<template>
  <div class="my-1">
    <div class="mb-0 d-flex align-items-center bs-toggle">
      <div
        class="cursor-pointer collection-name color-neutral-black"
        @click="buyingSummaryTableVisible = !buyingSummaryTableVisible"
      >
        {{ cart.storeName }}
      </div>
      <feather-icon
        class="ml-1"
        :icon="buyingSummaryTableVisible ? 'ChevronUpIcon' : 'ChevronDownIcon'"
        size="24"
        @click="buyingSummaryTableVisible = !buyingSummaryTableVisible"
      />
      <div class="d-lg-flex align-items-center ml-1">
        <b-badge class="p-50 values-badge color-neutral-black">
          Total units: {{ formatNumber(cartValue.totalUnits) || 0 }}
        </b-badge>
        <b-badge class="p-50 values-badge color-neutral-black">
          Total cost: {{ formatCurrency(Number(cartValue.totalValues), cartValue.currency) }}
        </b-badge>
        <b-badge class="p-50 values-badge color-neutral-black">
          Total RRP: {{ formatCurrency(Number(cartValue.totalRRP), cartValue.currency) }}
        </b-badge>
      </div>
    </div>
    <b-collapse
      id="buying_summary_collapse"
      v-model="buyingSummaryTableVisible"
      class="mt-1"
    >
      <div
        v-if="tableFields.length"
        class="mt-2 table-responsive summary-table h-100"
      >
        <b-table
          :fields="headings"
          :items="tableFields"
        >
          <template #cell(Pieces)="data">
            <span> {{ data.item.Pieces ? formatNumber(data.item.Pieces) : 0 }}</span>
          </template>
          <template #cell(TotalCost)="data">
            <span v-if="data.item"> {{ formatCurrency(Number(data.item.TotalCost), data.item.currency) }} </span>
          </template>
          <template #cell(RRPTotal)="data">
            <span v-if="data.item"> {{ formatCurrency(Number(data.item.RRPTotal), data.item.currency) }}</span>
          </template>
          <template #cell(Budget)="data">
            <b-form-input
              v-if="data.item.OptionCount"
              v-model.number="data.item.Budget"
              type="number"
              class="summary-table-input-width"
              min="0"
              @input="updateVariance(data)"
            />
          </template>
          <template #cell(Variance%)="data">
            <b-badge
              v-if="data.item.Variance"
              pill
              :variant="data.item.Variance.split('%')[0] > 0 ? 'danger' : 'success'"
            >
              {{ data.item.Variance }}
            </b-badge>
          </template>
        </b-table>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  BTable, BFormInput, BBadge,
  BCollapse,
} from 'bootstrap-vue'
import { reactive } from '@vue/composition-api'
import { kpApi, kpEndpoint } from '@/axios'
import { utils, constants as c, productUtil } from '@kingpin-global/kingpin-utils-frontend'
import { formatNumber } from '@/@core/utils/utils'

import { BUYING_SUMMARY_TABLE_FIELDS } from '@/table-columns-constants'
import { getCategorisedProduct } from './buying-summary-utils'

const { getProductAndCartTotals } = productUtil

const { formatCurrency } = utils

const { CURRENCY_CONVERSIONS } = c

export default {
  name: 'BuyingSummaryV2',
  components: {
    BTable,
    BFormInput,
    BBadge,
    BCollapse,
  },
  props: {
    cart: {
      type: Object,
      required: true,
    },
    cartValue: {
      type: Object,
      required: true,
      default: () => ({
        totalValues: 0,
        totalUnits: 0,
        totalRRP: 0,
        currency: CURRENCY_CONVERSIONS.USD.SYMBOL,
      }),
    },
    setTableItems: {
      type: Function,
      default: () => {},
    },
    tableFields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      buyingSummaryTableVisible: true,
      headings: BUYING_SUMMARY_TABLE_FIELDS(),
    }
  },
  setup(props) {
    const tableItems = reactive([])
    const products = props.cart.products
    getProductAndCartTotals(products)

    // categorising each products
    const categorisedProduct = getCategorisedProduct(products)

    // Assign products value to TableItems
    const keys = Object.keys(categorisedProduct)
    if (Array.isArray(keys)) {
      keys.forEach(key => {
        tableItems.push(categorisedProduct[key])
      })
    }
    props.setTableItems(tableItems)

    // Update Variance on Input
    const updateVariance = data => {
      if (data.item.Budget > 0) data.item.Variance = `${(((data.item.TotalCost / data.item.Budget) - 1) * 100).toFixed(2)}%`
      else data.item.Variance = '0%'
    }

    return {
      products,
      updateVariance,
      formatNumber,
      formatCurrency,
      kpApi,
      kpEndpoint,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";

.bs-toggle {
  .collection-name {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .values-badge {
    background-color: $gray-200;
    margin: 4px;
  }
}
</style>
