export default [
    {
        title: '1. GENERAL TERMS',
        points: [
            `1.1 The sales of Kingpin Holdings LTD and any other subsidiaries or
        affiliates are subject to the following General Sales Conditions.
        Unless otherwise stated in a written agreement, general purchase
        conditions of the customer thus do not apply. Any exception thereby
        accepted in writing will apply only to the sale in question and not
        to any other sales.`,
        ],
    },
    {
        title: '2. ORDERS',
        points: [
            `2.1 Every order implies acceptance by the customer of these general
        conditions. Every order is personal to the customer and may not be
        transferred without prior consent of Kingpin Holdings LTD and any
        other subsidiaries or affiliates.`,

            `2.2 Kingpin Holdings LTD and any other subsidiaries or affiliates
        reserve the right not to fill an order or to request payment upon
        delivery, to a customer whose payments are overdue.`,

            `2.3 Shall be considered as valid only the orders signed both by the
        customer and by Kingpin Holdings LTD and any other subsidiaries or
        affiliates, or through its representatives or agents. Kingpin
        Holdings LTD and any other subsidiaries or affiliates reserve the
        right to modify or cancel any order in case the customer has not an
        adequate risk credit coverage for such order or for any duly
        justified reason whatsoever.`,

            `2.4 The customer may modify or cancel its order within (7) days of
        signature of such order. From this date, given the specific
        manufacturing of the products (such products are ordered rather than
        being supplied out of stocks) any modification or cancellation by
        the customer will entitle Kingpin Holdings LTD and any other
        subsidiaries or affiliates to charge penalty of 30% of the order’s
        amount.`,

            `2.5 No Style, material or design, or other matter is to be
        considered ‘exclusive to customer unless stated by us in writing.`,
            `2.6 Variation in shade or cloth at dyers/printers normal variation
        level will be allowed as acceptable and will not give rise to
        cancellation or alteration of the order, or to any claim by the
        customer`,
        ],
    },
    {
        title: '3. DELIVERIES',
        points: [
            `3.1 The delivery dates provided in the orders are given only as
        estimates of the date of departure from the warehouse. Possible
        delays will not confer on the customer the right to demand
        cancellation or termination of the contract, to refuse to accept a
        delivery of products or to claim damages or penalties.`,

            `3.2 Kingpin Holdings LTD and any other subsidiaries or affiliates
        will be released from its obligation to deliver by any accident or
        circumstances of force majeure, including without limitation events
        such as mobilization, war, partial or total strike, lock out,
        requisition, lack of raw materials, bankruptcy of suppliers or
        sub-contractors, fire, accident to machinery, interruption or delay
        in transport or any other event interfering with the operations of
        Kingpin Holdings LTD and any other subsidiaries or affiliates, its
        suppliers or sub-contractors. Furthermore, the customer hereby
        accepts and acknowledges that up to 20% of the total quantity of
        products stipulated in the order may not be supplied by Kingpin
        Holdings LTD and any other subsidiaries or affiliates.`,

            `3.3 Kingpin Holdings LTD and any other subsidiaries or affiliates
        will be released from its obligation to deliver products in the case
        of a breach by the customer of its obligations hereunder, including
        without limitation: - failure to fulfill the conditions of payment,
        - failure to provide information, authorizations or approvals within
        the time requested by Kingpin Holdings LTD and any other
        subsidiaries or affiliates.`,
        ],
    },
    {
        title: '4. PRICE',
        points: [
            `4.1 Kingpin Holdings LTD and any other subsidiaries or affiliates’
        products are delivered as per incoterms shared on the invoice.`,

            '4.2 The sale price does not include taxes.',

            `4.3 In case of late collection, Kingpin Holdings LTD and any other
        subsidiaries or affiliates reserve the right to bill a supplementary
        fixed charge for storage.`,
        ],
    },
    {
        title: '5. PAYMENT',
        points: [
            `5.1 Dates of payment shall be determined from the date of invoice
        and not from the date of receipt of merchandise.`,

            `5.2 Unless agreed otherwise, Kingpin Holdings LTD and any other
        subsidiaries or affiliates require pre-payment of the purchase price
        prior to delivery of the products.`,

            `5.3 In case of overdue payment or delay in accepting a draft,
        Kingpin Holdings LTD and any other subsidiaries or affiliates shall
        have the right, fifteen (15) days after giving formal notice, to
        cancel all previously agreed delays of payment. All pending and
        outstanding invoices shall become immediately due and payable as a
        consequence.`,

            `5.4 Any overdue payment shall automatically give rise to interest at
        a rate of 1% per month or any part of month of delay in payment,
        without prejudice to Kingpin Holdings LTD and any other subsidiaries
        or affiliates to request from the defaulting customer, for the next
        orders, payment upon delivery.`,

            `5.5 Failure to pay a draft or to return an accepted draft shall give
        Kingpin Holdings LTD and any other subsidiaries or affiliates the
        right to suspend all pending sales.`,

            `5.6 Customers who fail to make payments timely according to their
        due dates shall bear the costs of legal proceedings.`,

            `5.7 No complaint shall be deemed a valid reason for delaying payment
        or for not returning drafts after acceptance in a reasonable delay.`,

            `5.8 No debt may be offset against a credit if the offset has not
        been previously accepted in writing by Kingpin Holdings LTD and any
        other subsidiaries or affiliates.`,

            `5.9 A claim by the customer shall at no time be a ground for
        withholding payment of any sum due to us nor shall it give rise to
        any set-off against the payment due to Kingpin Holdings LTD and any
        other subsidiaries or affiliates.`,
        ],
    },
    {
        title: '6. RESERVATION OF OWNERSHIP',
        points: [
            `6.1 Kingpin Holdings LTD and any other subsidiaries or affiliates
            reserves ownership of the delivered merchandise until complete
            payment of the price by the customer.
            In this connection, the
            remittance of drafts or any instrument creating the obligation to
            pay does not constitute payment for purposes of this clause. The
            customer is authorized, in the course of the normal management of
            its business, to resell the delivered merchandise, but it may
            neither pledge nor transfer the ownership there of for security
            purposes.`,

            `6.2 In case of resale, the customer undertakes to remit immediately
            to Kingpin Holdings LTD and any other subsidiaries or affiliates
            that part of the price which is payable. The authorization to resell
            is automatically withdrawn in case of insolvency of the customer.`,

            `6.3 Notwithstanding the here above provisions, the customer will
            bear the risk of loss or destruction of merchandise once the same
            leaves Kingpin Holdings LTD and any other subsidiaries or
            affiliates’ factory or warehouse.`,
        ],
    },
    {
        title: '7. WARRANTY - RETURNS',
        points: [
            `7.1 The liability of Kingpin Holdings LTD and any other subsidiaries
            or affiliates can only be invoked or sought for reasons of
            substantial imperfections or defects affecting the merchandise.
            Differences in quality, color, size, weight, finishing, design, or
            irregularities within the normal range occurring in the business,
            negligible or inevitable in production, shall not be considered as
            substantial defects.`,

            `7.2 No claim shall be accepted if not made in the form of a
            registered letter within eight (8) days of receipt of merchandise.`,

            `7.3 Any return of products must be agreed between Kingpin Holdings
            LTD and any other subsidiaries or affiliates and the customer: any
            product which is returned without such an agreement shall not be
            taken back by Kingpin Holdings LTD and any other subsidiaries or
            affiliates and shall remain at the customer’s disposal.`,

            `7.4 Any goods returned without our prior consent or having been so
            returned, any complaints made which are rejected by us, shall be
            held entirely at the customer’s own risk and expenses. It is
            customer sole responsibility to ensure that any goods returned are
            received by us.
            The customer shall bear the costs and risks on the returned
            products. Once accepted by Kingpin Holdings LTD and any other
            subsidiaries or affiliates, upon receipt of the returned products
            and verification of the quality and quantity of such returned
            products, the customer will either be granted a credit, a
            reimbursement or the said products will be replaced, in the sole
            discretion of Kingpin Holdings LTD and any other subsidiaries or
            affiliates to the exclusion of any indemnification or compensation.`,
        ],
    },
    {
        title: '8. OBLIGATIONS OF THE CUSTOMER',
        points: [
            `8.1 The ordered products are to be sold under such conditions that
            will ensure distribution compatible with the renown and the prestige
            attached to the trademarks of the brands.`,

            `8.2 The designs and models of Kingpin Holdings LTD and any other
            subsidiaries or affiliates may not be copied, altered or
            transformed. The customer is expressly prohibited from removing the
            trademark or label from Kingpin Holdings LTD and any other
            subsidiaries or affiliates’ products or from copying them. Shopping
            bags bearing the Kingpin Holdings LTD and any other subsidiaries or
            affiliates’ trademark must only be used for the Kingpin Holdings LTD
            and any other subsidiaries or affiliates’ products and not for the
            other products. The customer does not have the right to imitate
            Kingpin Holdings LTD and any other subsidiaries or affiliates’
            trademark and/or related logos or to make labels such as trademarks.`,

            `8.3 Any violation of the obligations set forth in this article shall
            be considered a serious and material breach on the part of the
            customer justifying immediate cancellation or termination of any
            contracts then in force.`,

            `8.4 The customer agrees to keep Kingpin Holdings LTD and any other
            subsidiaries or affiliates informed of changes in its legal status,
            place of business, bank, etc.
            No variation may be made to the
            above conditions unless agreed in writing`,
        ],
    },
    {
        title: '9. DATA PROTECTION AND USE',
        points: [
           `9.1 Each Party shall comply with all applicable laws and the Terms in relation to the
           privacy and security of any personal data to which it gains access in the course of
           using the Platform (Data).`,
           `9.2 Each Party shall take appropriate technical and organisational measures against
           unauthorised or unlawful processing of the Data or its accidental loss, destruction
           or damage.`,
         ],
    },
    {
        title: '10. CONFIDENTIALITY',
        points: [
            `10.1 Either Party may be given access to information relating the business or
            personnel of the other Party in order to perform its obligations under this Agreement
            (Confidential Information). A Party's Confidential Information shall not be deemed to
            include information that:`,
            `(i) is or becomes publicly known other than through any act or
            omission of the receiving Party;`,
            '(ii) was in the other Party\'s lawful possession before the disclosure;',
            `(iii) is lawfully disclosed to the receiving Party by a third party without
            restriction on disclosure;`,
            `(iv) is independently developed by the receiving Party, which
            independent development can be shown by written evidence; `,
            'or',
            `(v) is required to be disclosed by law, by any court of competent
            jurisdiction or by any regulatory or administrative body having
            jurisdiction over the receiving Party.`,
            `10.2 Each Party shall hold the other's Confidential Information in confidence and not
            make the other's Confidential Information available to any third party or use the
            other's Confidential Information for any purpose other than the implementation of
            this Agreement.`,
            `10.3 Each Party shall take all reasonable steps to ensure that the other's Confidential
            Information to which it has access is not disclosed or distributed by its employees or
            agents in violation of the terms of this Agreement.`,
            `10.4 The User shall not make, or permit any person to make, any public
            announcement concerning this Agreement without the prior written consent of
            Kingpin Holdings LTD and any other subsidiaries or affiliates, except as required by
            law, any governmental or regulatory authority (including any relevant securities
            exchange), or any court or other authority of competent jurisdiction.`,
            `10.5 The above provisions of this clause shall survive for a period of one year from the
            consolidation of this Agreement, however arising.`,
        ],
    },
    {
        title: '11. GOVERNING LAW',
        points: [
            `11.1 This agreement and any dispute or claim arising out of or in connection with it or
            its subject matter or formation (including non-contractual disputes or claims) shall
            be governed by and construed in accordance with the laws applicable in Abu Dhabi
            Global Market.`,
        ],
    },
]
