<template>
  <div class="w-100 d-none font-inter cart-product-error-border">
    <div class="text-uppercase">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
export default {
    name: 'CartProductErrorText',
    props: {
        errorText: {
            type: String,
            default: '',
        },
    },
}
</script>
