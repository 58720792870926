<template>
  <svg
    width="24"
    height="17"
    viewBox="0 0 24 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9699 0.210815C20.0682 0.210815 21.1215 0.647117 21.8981 1.42374C22.6747 2.20036 23.111 3.25368 23.111 4.35199C23.111 5.4503 22.6747 6.50363 21.8981 7.28025C21.1215 8.05687 20.0682 8.49317 18.9699 8.49317C17.8715 8.49317 16.8182 8.05687 16.0416 7.28025C15.265 6.50363 14.8287 5.4503 14.8287 4.35199C14.8287 3.25368 15.265 2.20036 16.0416 1.42374C16.8182 0.647117 17.8715 0.210815 18.9699 0.210815ZM16.8737 2.16018L16.821 2.2031L16.778 2.2558C16.7347 2.31867 16.7114 2.39325 16.7114 2.46964C16.7114 2.54603 16.7347 2.62061 16.778 2.68348L16.821 2.73618L18.4375 4.35199L16.821 5.9678L16.778 6.02051C16.7347 6.08338 16.7114 6.15796 16.7114 6.23434C16.7114 6.31073 16.7347 6.38531 16.778 6.44818L16.821 6.50089L16.8737 6.5438C16.9365 6.58719 17.0111 6.61043 17.0875 6.61043C17.1639 6.61043 17.2385 6.58719 17.3013 6.5438L17.354 6.50089L18.9699 4.88432L20.5857 6.50089L20.6384 6.5438C20.7012 6.58719 20.7758 6.61043 20.8522 6.61043C20.9286 6.61043 21.0032 6.58719 21.066 6.5438L21.1187 6.50089L21.1617 6.44818C21.2051 6.38531 21.2283 6.31073 21.2283 6.23434C21.2283 6.15796 21.2051 6.08338 21.1617 6.02051L21.1187 5.9678L19.5022 4.35199L21.1187 2.73618L21.1617 2.68348C21.2051 2.62061 21.2283 2.54603 21.2283 2.46964C21.2283 2.39325 21.2051 2.31867 21.1617 2.2558L21.1187 2.2031L21.066 2.16018C21.0032 2.11679 20.9286 2.09356 20.8522 2.09356C20.7758 2.09356 20.7012 2.11679 20.6384 2.16018L20.5857 2.2031L18.9699 3.81966L17.354 2.2031L17.3013 2.16018C17.2477 2.12305 17.1854 2.10048 17.1205 2.09467C17.0555 2.08887 16.9902 2.10002 16.9309 2.12705L16.8737 2.16018Z"
      fill="currentColor"
    />
    <path
      d="M13.9832 3.98221H5.90652L4.83827 1.46824C4.6982 1.13718 4.46102 0.854352 4.15679 0.655615C3.85257 0.456878 3.49501 0.351184 3.12943 0.351933H0.969788V2.16707H3.12943L7.52093 12.4997C7.59127 12.6651 7.70994 12.8063 7.86198 12.9056C8.01402 13.0049 8.19265 13.0579 8.37535 13.0579H15.7809C16.1669 13.0579 16.5122 12.8228 16.6483 12.4698L17.8859 9.23413C15.6456 8.73898 13.9698 6.74116 13.9698 4.35193C13.9698 4.22758 13.9743 4.10428 13.9832 3.98221Z"
      fill="currentColor"
    />
    <path
      d="M8.8382 16.6882C9.60507 16.6882 10.2267 16.0787 10.2267 15.3268C10.2267 14.575 9.60507 13.9655 8.8382 13.9655C8.07133 13.9655 7.44965 14.575 7.44965 15.3268C7.44965 16.0787 8.07133 16.6882 8.8382 16.6882Z"
      fill="currentColor"
    />
    <path
      d="M16.7066 15.3268C16.7066 16.0787 16.0849 16.6882 15.3181 16.6882C14.5512 16.6882 13.9295 16.0787 13.9295 15.3268C13.9295 14.575 14.5512 13.9655 15.3181 13.9655C16.0849 13.9655 16.7066 14.575 16.7066 15.3268Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ShoppingBagDeleteteIcon',
}
</script>
