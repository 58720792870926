<template>
  <div :id="componentId">
    <b-modal
      id="clear-cart"
      centered
      hide-footer
      hide-header
      hide-header-close
    >
      <div
        class="
          pb-5-px
          border-bottom
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <div class="elements-center">
          <span class="modal-rounded-icon">
            <shopping-bag-deletete-icon />
          </span>
          <span class="custom-modal-heading color-primary">
            Clear my cart
          </span>
        </div>
        <feather-icon
          class="float-right color-neutral-black cursor-pointer"
          icon="XIcon"
          size="24"
          @click="$bvModal.hide('clear-cart')"
        />
      </div>
      <div class="mt-1">
        <b-card-text class="mb-1">
          The cart will be permanently cleared
        </b-card-text>
        <b-card-text> Are you sure you want to proceed? </b-card-text>
        <div class="elements-center my-1">
          <b-button
            variant="outline-secondary-black mr-1"
            @click="$bvModal.hide('clear-cart')"
          >
            Cancel
          </b-button>
          <b-button
            variant="danger"
            @click="clearCart"
          >
            Yes, clear it
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="import_cart_modal"
      size="lg"
      modal-class="modal-primary"
      centered
      title="Upload Cart"
      hide-footer
    >
      <div>
        <label
          v-if="!cartUploadFile"
          for="uploadCatalog"
          class="fileDownload"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="50"
            color="skyblue"
          />
          <h4 class="font-weight-bolder"><b>Drag & Drop</b></h4>
          <h6>or click to select files from your system</h6>
        </label>
        <div
          v-if="cartUploadFile"
          class="d-flex file-droped mt-2"
        >
          <xlsx-icon class="cart-xlsx-icon" />
          <span
            class="catalog-label cart-file-name"
          >Name: {{ cartUploadFile.name }}
          </span>
          <span
            class="catalog-label cart-file-size"
          >Size: {{ (formatNumber(cartUploadFile.size / 1024), 2) }} KB</span>
          <div
            v-if="cartUploadFile"
            class="delete-file d-flex align-items-center justify-content-center"
            @click="cartUploadFile = null"
          >
            <feather-icon
              icon="TrashIcon"
              size="24"
              fill="white"
              color="white"
            />
          </div>
        </div>
        <b-form-file
          id="uploadCatalog"
          v-model="cartUploadFile"
          name="uploadCatalog"
          accept=".xlsx"
          :hidden="true"
          plain
        />
      </div>
      <div>
        <div class="text-center mt-1">
          <b-button
            type="reset"
            class="mt-2 mr-2 px-sm-3 font-weight-bolder"
            variant="outline-info"
            @click="closeModal"
          >
            Cancel
          </b-button>
          <b-button
            class="mt-2 px-sm-3 font-weight-bolder"
            variant="info"
            :disabled="!cartUploadFile || isUploading"
            @click="syncCartProducts()"
          >
            {{ isUploading ? 'Uploading...' : 'Upload' }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-upload-cart-conflict"
      ok-variant="warning"
      ok-title="Yes, I understand"
      modal-class="modal-warning"
      centered
      title="Add to Cart"
      @ok="syncCartProducts(false, true)"
      @hide="$bvModal.show('modal-upload-cart-conflict')"
    >
      <b-card-text>
        {{ cartUploadConflictMessage }}
      </b-card-text>
    </b-modal>
    <b-modal
      id="clear-retailer-cart"
      centered
      hide-footer
      hide-header
      hide-header-close
    >
      <div
        class="
          pb-5-px
          border-bottom
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <div class="elements-center">
          <span class="modal-rounded-icon">
            <shopping-bag-deletete-icon />
          </span>
          <span class="custom-modal-heading color-primary">
            Delete retailer cart
          </span>
        </div>
        <feather-icon
          class="float-right color-neutral-black cursor-pointer"
          icon="XIcon"
          size="24"
          @click="$bvModal.hide('clear-retailer-cart')"
        />
      </div>
      <div class="mt-1">
        <b-card-text class="mb-1">
          Retailer has some items in their cart
        </b-card-text>
        <b-card-text> Are you sure you want to proceed to delete retailer's cart? </b-card-text>
        <div class="elements-center my-1">
          <b-button
            variant="outline-secondary-black mr-1"
            @click="$bvModal.hide('clear-retailer-cart')"
          >
            Cancel
          </b-button>
          <b-button
            variant="danger"
            @click="onClickDeleteRetailerCart"
          >
            Yes, delete it
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
 BModal, VBModal, BButton, BCardText, BFormFile,
} from 'bootstrap-vue'
import ShoppingBagDeleteteIcon from '@/@core/assets/svg-components/ShoppingBagDeleteteIcon.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { UPLOAD_DOCUMENTS } from '@/store/modules/common.module'
import analytics from '@/@core/utils/analytics'
import constants, { FILE_FORMATS } from '@/constants'
import { formatNumber, formatObject, parseErrorObject } from '@/@core/utils/utils'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { FETCH_CART_COUNT, IMPORT_CART } from '@/store/modules/shop.module'
import { apiToastError, apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import XlsxIcon from '@/@core/assets/svg-components/XlsxIcon.vue'
import NotificationMixin from '@/views/mixins/NotificationMixin.vue'

const { FILE_TYPES } = c

export default {
  components: {
    BModal,
    ShoppingBagDeleteteIcon,
    BButton,
    BCardText,
    BFormFile,
    FeatherIcon,
    XlsxIcon,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [NotificationMixin],
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    retailerId: {
      type: String,
      default: '',
    },
    clearCart: {
      type: Function,
      default: () => {},
    },
    onCartImportSuccess: {
      type: Function,
      default: () => {},
    },
    placeOrder: {
      type: Function,
      default: () => {},
    },
    cartProducts: {
      type: Object,
      default: () => {},
    },
    onClickExportCart: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isExporting: false,
      isUploading: false,
      cartUploadFile: null,
      cartUploadConflictMessage: '',
      formatNumber,
      componentId: null,
    }
  },
  mounted() {
    this.componentId = `cart_modals_${this._uid}`
  },
  methods: {
    async syncCartProducts(event, shouldProceed = false) {
      if (this.cartUploadFile) {
        this.isUploading = true
        this.cartUploadFileLink = await this.$store.dispatch(UPLOAD_DOCUMENTS, {
          fileType: FILE_TYPES.CART_UPLOAD,
          file: this.cartUploadFile,
        })
        const payload = {
          cartProductsFile: this.cartUploadFileLink,
        }
        const params = {}
        if (shouldProceed) {
          params.shouldProceed = true
        }
        if (this.isPopup) {
          payload.retailerId = this.retailerId
        }
        this.$store
          .dispatch(IMPORT_CART, { payload, params })
          .then(async res => {
            this.closeModal()
            apiToastSuccess(res.data.message)
            this.onCartImportSuccess()
            this.isUploading = false
            this.cartUploadFile = null
            if (!this.isPopup) await this.$store.dispatch(FETCH_CART_COUNT)
            analytics.track(
              constants.TRACKS.ACTIONS.RETAILER_UPLOADS_CART,
              formatObject({ fileFormat: FILE_FORMATS.XLSX, status: 'Success' }),
            )
          })
          .catch(err => {
            this.isUploading = false
            const failedCarts = err?.response?.data?.data?.failedCarts ?? []
            if (failedCarts.length) {
             failedCarts.forEach(errObj => {
              apiToastError(`${errObj?.storeName} - ${errObj?.error}`)
             })
            } else if (err?.response?.status === 425) {
              this.cartUploadConflictMessage = err.response.data.message || err.message
              this.$bvModal.hide('import_cart_modal')
              this.$bvModal.show('modal-upload-cart-conflict')
            } else {
              apiToastWarning(err)
              analytics.track(
                constants.TRACKS.ACTIONS.RETAILER_UPLOADS_CART,
                formatObject({
                  fileFormat: FILE_FORMATS.XLSX,
                  status: 'Error',
                  Error: parseErrorObject(err),
                }),
              )
            }
          })
      } else {
        apiToastWarning('Please Select a File')
      }
    },
    closeModal() {
      this.$bvModal.hide('import_cart_modal')
      this.cartUploadFile = null
    },
    async onClickDeleteRetailerCart() {
      if (this.cartProducts.isCartExport) {
        await this.clearCart(this.retailerId)
        this.onClickExportCart()
      } else {
        this.placeOrder(true)
      }
    },
  },
}
</script>
