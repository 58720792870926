<template>
  <div
    :id="cartId+'_products'"
    class="checkout-items"
  >
    <!-- loaded -->
    <template v-if="isLoaded">
      <!-- has product -->
      <template v-if="productsSlice.length || originalProducts.length">
        <!-- Cart errors -->
        <cart-errors
          v-if="isCartHasErrors"
          :key="isCartHasErrors+cartId"
          :cart-id="cartId"
          :cart-products="products"
        />
        <div
          v-for="product in productsSlice"
          :key="product._id"
          class="mb-1 cart-product"
          :class="{'has-error' : isNotValidMinQty(product, product.lineTotalQuantity), 'is-product-not-valid' : isCartHasErrors && getCartProductsErrorStatusText(product), 'zero-qty-product': isCartHasErrors && isProductHasZeroQty(product)}"
        >
          <!-- cart error text -->
          <cart-product-error-text
            :error-text="getCartProductsErrorStatusText(product)"
          />
          <b-row
            class="mx-0"
            no-body
          >
            <!-- Product Image -->
            <b-col class="item-img col-md-3 col-xl-2 pl-0 pr-0 col-12">
              <b-link
                :to="{name: 'product-detail', params: {id: product._id, collectionId: product.collectionId}}"
              >
                <b-img-lazy
                  class="imgcss"
                  :src="parseProductImage(product)"
                  :alt="`${product.name}-${product._id}`"
                  @error.native="imageLoadError"
                />
              </b-link>
            </b-col>

            <!-- Product Details: Card Body -->
            <b-col class="col-md-9 col-xl-10 p-2 d-flex flex-column col-12">
              <div class="item-name d-flex justify-content-between">
                <div>
                  <b-link
                    class="product-link"
                    :to="{name: 'product-detail', params: {id: product._id, collectionId: product.collectionId}}"
                  >
                    {{ product.name }}
                  </b-link>
                  <b-link class="company-name">
                    <b-breadcrumb
                      class="breadcrumb-products mb-0 pl-0"
                      :items="[product.department, product.category, product.subCategory]"
                    />
                  </b-link>
                  <div class="cart-price-title">
                    <div class="mt-75">
                      Style Code: <span class="cart-price-value">{{ product.styleCode }}</span>
                    </div>
                    <div>
                      Retail Price: <span class="cart-price-value">{{ formatCurrency(product.retailPrice, product.currency) }}</span>
                    </div>
                    <div>
                      Wholesale Selling Price:
                      <span
                        v-if="!product.discountedPrice"
                        class="cart-price-value"
                        :class="{'text-line-through color-red': !!product.discountedPrice}"
                      >
                        {{ formatCurrency(product.price, product.currency) }}
                      </span>
                      <span
                        v-if="product.discountedPrice"
                        class="cart-price-value discounted-price"
                      >
                        {{
                          formatCurrency(product.discountedPrice, product.currency)
                        }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <div
                    v-if="collectionEndDate"
                    class="font-weight-normal mt-50"
                  >
                    <span
                      class="mb-2 cart-price-title"
                    >
                      Order Deadline:
                      <span class="cart-price-value">{{ formatDate(collectionEndDate) }} </span>
                    </span>
                  </div>
                  <div class="font-weight-normal mt-50">
                    <span
                      v-if="product.shippingStartDate && product.shippingEndDate"
                      class="mb-2 cart-price-title"
                    >
                      Availability Date:
                      <span class="cart-price-value">{{ formattedDate(product.shippingStartDate) }} to {{ formattedDate(product.shippingEndDate) }}</span>
                    </span>
                  </div>
                  <div
                    v-if="product.isCarton"
                    class="item-quantity mt-1 mr-1"
                  >
                    <span class="cart-price-title">Qty:</span>
                    <b-form-spinbutton
                      v-model="product.quantity"
                      size="sm"
                      class="ml-75"
                      inline
                      :max="getMaxQuantity(product.quantityAvailable)"
                      :disabled="isProductValueDisabled(product.quantityAvailable)"
                      min="1"
                      @input="updateQuantity(product)"
                    />
                    <div
                      v-if="hasProductMaxQuantity(product.quantityAvailable)"
                      class="text-nowrap text-right color-actions-text-grey mt-2-px mr-1"
                    >
                      Max Qty: {{ product.quantityAvailable }} </div>
                  </div>
                </div>
                <div>
                  <div class="d-flex justify-content-end">
                    <span
                      v-b-modal="`modal_remove_product_${product._id}_${cartId}`"
                      v-b-tooltip.hover.bottom.v-primary
                      class=""
                      title="Delete Product from Cart"
                    >
                      <delete-icon />
                    </span>
                  </div>

                  <b-modal
                    :id="`modal_remove_product_${product._id}_${cartId}`"
                    ok-variant="danger"
                    ok-title="Remove product"
                    cancel-title="Dismiss"
                    modal-class="modal-danger"
                    centered
                    title="Remove this product?"
                    @ok="removeProduct(product)"
                  >
                    <b-card-text>
                      Are you sure you want to delete this product?
                    </b-card-text>
                  </b-modal>

                  <div class="item-wrapper mt-1 price-card p-1">
                    <div class="item-cost d-flex d-md-block">
                      <div
                        v-if="variantLoaded"
                        class="text-secondary mr-50 mr-md-0"
                      >
                        Total units:
                        {{ formatNumber(product.lineTotalQuantity || 0) }}
                      </div>
                      <div
                        v-if="variantLoaded"
                        class="item-price pb-0 mt-1 h5 font-weight-bolder color-neutral-black"
                      >
                        Total cost:
                        {{ formatCurrency(Number(product.lineTotalPriceWithDiscount || product.lineTotalPrice || 0) , product.currency) }}
                      </div>
                      <p
                        v-if="!!minQtyErrorMessage(product)"
                        class="text-primary"
                      >
                        {{ minQtyErrorMessage(product) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- variants -->
              <product-variants-v-2
                :is-deleted="product.isDeleted"
                :is-carton="product.isCarton"
                :items="product.items"
                :is-edit-enabled="true"
                :min-qty-per-size="product.minQtyPerSize || 0"
                @update-variants="
                  (variants) => updateProductVariants(variants, product)
                "
              />
            </b-col>
          </b-row>
        </div>
        <!-- loading more products -->
        <template v-if="isLoadingMoreProducts">
          <div class="text-center p-4">
            <b-spinner />
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import DeleteIcon from '@/@core/assets/svg-components/DeleteIcon.vue'
// import RecycleIcon from '@/@core/assets/svg-components/RecycleIcon.vue'
import analytics from '@/@core/utils/analytics'
import { formatDate } from '@/@core/utils/filter'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import {
formatNumber, formatObject,
getMaxQuantity, hasProductMaxQuantity, imageLoadError,
isProductValueDisabled,
} from '@/@core/utils/utils'
import constants, { marketplaceAccessDeniedMessage } from '@/constants'
import store from '@/store'
import {
  REMOVE_PRODUCT_FROM_CART_BY_CART_ID, SET_REPLACE_CART_PRODUCT, UPDATE_CART_ITEMS_COUNT,
} from '@/store/modules/shop.module'
import { constants as c, utils, productUtil } from '@kingpin-global/kingpin-utils-frontend'

import {
onMounted, onUnmounted, ref,
} from '@vue/composition-api'
import {
BBreadcrumb,
BCardText,
BCol,
BFormSpinbutton,
BImgLazy,
BLink,
BModal,
BRow,
BSpinner,
VBModal,
VBTooltip,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import UserRoleMixinVue from '@/views/apps/UserRoleMixin.vue'
import { useEcommerceUi } from '@/views/apps/useEcommerce'
import { REMOVE_PRODUCTS_FROM_CARTS, UPDATE_CART_ERRORS } from '@/store/modules/checkout-v2.module'
import CartProductErrorText from '@/views/components/cart-products-error/CartProductErrorText.vue'
import CartErrorsMixinVue from '@/views/components/cart-products-error/CartErrorsMixin.vue'
import {
  getCartProductsErrorStatusText,
 getSortedCartProducts,
 initializeData, isNotValidMinQty, isProductHasZeroQty, minQtyErrorMessage, parseProductItems,
} from '../checkout/cart-products-utils'
import ProductVariantsV2 from './components/ProductVariantsV2.vue'
import CartErrors from '../../components/cart-products-error/CartErrors.vue'

const { getProductAndCartTotals } = productUtil

const { formatCurrency, formattedDate, round } = utils

const { BRAND_ACCESS } = c

const CART_WITH_MULTIPLE_STORES = constants.TRACKS.ACTIONS.CART_WITH_MULTIPLE_STORES

const PRODUCTS_LIMIT = 5
export default {
  name: 'CartProducts',
  components: {
    BCardText,
    BLink,
    BImgLazy,
    BBreadcrumb,
    BSpinner,
    BRow,
    BCol,
    BModal,
    // RecycleIcon,
    DeleteIcon,
    BFormSpinbutton,
    ProductVariantsV2,
    CartErrors,
    CartProductErrorText,
},
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixinVue, CartErrorsMixinVue],
  props: {
    storeName: {
      type: String,
      default: () => '',
    },
    cartId: {
      type: String,
      default: () => null,
    },
    cartEntityId: {
      type: String,
      default: () => null,
    },
    cartProducts: {
      type: Array,
      default: () => [],
    },
    collectionEndDate: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      imageLoadError,
      formatDate,
      getMaxQuantity,
      isProductValueDisabled,
      hasProductMaxQuantity,
      marketplaceAccessDeniedMessage,
      isNotValidMinQty,
      minQtyErrorMessage,
      getCartProductsErrorStatusText,
      isProductHasZeroQty,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profileData,
    }),
    isEnabledMarketplace() {
      return this.profile?.access?.includes(BRAND_ACCESS.MARKETPLACE)
    },
  },
  emits: ['update-total', 'update-total-units', 'has-cart-product'],

  setup(props, { emit, root }) {
    let productsLimit = PRODUCTS_LIMIT
    let [productsSliceX, productsSliceY, pageNumber] = [0, 0, 1]
    let totals = {
      totalPrice: 0,
      totalQuantity: 0,
      totalPriceWithDiscount: 0,
    }
    const isLoadingMoreProducts = ref(false)

    const {
      variantLoaded,
      isLoaded,
      products,
      productsSlice,
      originalProducts,
      discountInCredit,
      storeId,
      isCartExport,
    } = initializeData(root)

    const {
      onScrollLoader,
      removeProduct,
      onClickReplace,
      updateTotalPriceAndUnits,
      updateProductVariants,
      updateQuantity,
    } = setupFunctions({
      emit,
      root,
      products,
      productsSlice,
      originalProducts,
    })
    let documentCart
    onMounted(() => {
       documentCart = document.getElementById(`${props.cartId}_products`)
       documentCart.addEventListener('scroll', onScrollLoader)
    })

    onUnmounted(() => {
      documentCart.removeEventListener('scroll', onScrollLoader)
    })

    if (props.cartProducts.length > 0) {
      initializeWithCartProducts(props.cartProducts)
    }

    const { parseProductImage } = useEcommerceUi()

    return {
      products,
      productsSlice,
      isLoaded,
      variantLoaded,
      formattedDate,
      originalProducts,
      discountInCredit,
      storeId,
      isCartExport,
      isLoadingMoreProducts,

      // UI
      removeProduct,
      onClickReplace,
      parseProductImage,
      updateProductVariants,

      // Filter
      formatNumber,
      formatCurrency,
      updateTotalPriceAndUnits,
      updateQuantity,
      initializeWithCartProducts,
    }

    function setupFunctions({
      emit,
      root,
      products,
      productsSlice,
      originalProducts,
    }) {
      const userAssociation = root.$store.getters.getCurrentAssociation
      const loadMoreProducts = () => {
        pageNumber += 1
        productsSliceY = products.value.length < pageNumber * productsLimit
            ? products.value.length
            : pageNumber * productsLimit

        products.value
          .slice(productsSliceX, productsSliceY)
          .forEach(p => productsSlice.value.push(p))

        productsSliceX = productsSliceY
      }

      const onScrollLoader = () => {
        if (documentCart.scrollTop > documentCart.scrollHeight - 850 && products.value.length > productsSliceX) {
          isLoadingMoreProducts.value = true
          setTimeout(() => {
            loadMoreProducts()
            isLoadingMoreProducts.value = false
          }, 1000)
        }
      }

      const removeProduct = product => {
        removeProductFromCart(product)
      }

      const removeProductFromProductList = product => {
        const filterOutProduct = prod => prod._id !== product._id
        products.value = products.value.filter(filterOutProduct)
        originalProducts.value = originalProducts.value.filter(filterOutProduct)
        productsSlice.value = productsSlice.value.filter(filterOutProduct)
        root.$store.dispatch(REMOVE_PRODUCTS_FROM_CARTS, { cartId: props.cartId, productIds: [product._id] })
      }

      const removeProductFromCart = async product => {
        try {
          const removeProductResponse = await store.dispatch(REMOVE_PRODUCT_FROM_CART_BY_CART_ID, {
            cartId: props.cartId,
            productId: product._id,
          })

          analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_CLICKED_REMOVE_PRODUCT_BUTTON, {
            cartId: props.cartId,
            productId: product._id,
            storeName: props.storeName || '',
            ...formatObject(product),
          })

          apiToastSuccess(removeProductResponse.data.message || 'Removed product from cart!')
          removeProductFromProductList(product)
          updateTotalPriceAndUnits()
          if (props.cartEntityId === userAssociation?.entityId) {
            store.commit(
              UPDATE_CART_ITEMS_COUNT,
              products.value.length,
            )
          }
          store.commit(UPDATE_CART_ERRORS)
        } catch (err) {
          apiToastWarning(err)
        }
      }

      const onClickReplace = async product => {
        root.$store.commit(SET_REPLACE_CART_PRODUCT, product)
        await root.$router.push({
          name: 'collection/products',
          params: { storeId: product.storeId },
        })
      }

      const calculateLineRRP = product => {
        product.lineRRP = 0
        product.items.forEach(item => {
          product.lineRRP += product.retailPrice * (product.quantity || 1) * item.value
        })
        return product.lineRRP
      }

      const updateTotalPriceAndUnits = () => {
        // total RRP
        let totalRRP = 0
        products.value.forEach(product => {
          const lineRRP = calculateLineRRP(product)
          totalRRP += lineRRP
        })
        totalRRP = round(totalRRP) || 0

        // Total WSP and quantities
        totals = getProductAndCartTotals(
          products?.value,
          products?.value[0]?.discount,
        )
        emit(
          'update-total',
          { totalMRP: totals?.totalPriceWithDiscount || totals?.totalPrice || 0, totalRRP },
        )
        emit('update-total-units', totals.totalQuantity)
      }

      // update product variant => recalculate total
      const updateProductVariants = (variants, product) => {
        product.variants = variants
        const items = parseProductItems(product)
        product.items = items
        const index = originalProducts.value.findIndex(
          p => p._id === product._id,
        )
        originalProducts.value[index].items = items

        if (!product.isCarton) {
          originalProducts.value[index].hasError = product?.hasError || false
        }

        updateTotalPriceAndUnits()
        variantLoaded.value = true
      }

      const updateQuantity = product => {
        const index = originalProducts.value.findIndex(
          p => p._id === product._id,
        )
        originalProducts.value[index].quantity = product.quantity
        updateTotalPriceAndUnits()
      }

      return {
        onScrollLoader,
        removeProduct,
        removeProductFromProductList,
        removeProductFromCart,
        onClickReplace,
        updateTotalPriceAndUnits,
        updateProductVariants,
        updateQuantity,
        initializeWithCartProducts,
      }
    }

    function initializeWithCartProducts(cartProducts, isErrorProducts = false) {
      productsLimit = PRODUCTS_LIMIT
      if (cartProducts?.length) {
        originalProducts.value = JSON.parse(JSON.stringify(cartProducts))
        if (isErrorProducts) {
          products.value = getSortedCartProducts(cartProducts)
          productsSlice.value = []
        } else {
          products.value = cartProducts
        }
        updateTotalPriceAndUnits()
        productsSliceY = products.value.length < productsLimit
            ? products.value.length
            : productsLimit
        products.value
          .slice(0, productsSliceY)
          .forEach(p => productsSlice.value.push(p))
        productsSliceX = productsSliceY
      }
      isLoaded.value = true
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/app-cart-products.scss';
</style>

<style lang="scss" scoped>
@import "@/@core/scss/base/bootstrap-extended/_variables.scss";

.checkout-items {
  max-height: 800px;
  overflow: auto;
  .cart-product {
    background-color: $gray-50;
    border-radius: 0;

    .price-card {
      min-width: 240px;
      background-color: $gray-200;
      border-radius: 6px;
    }
  }

  .item-img .imgcss {
    border-radius: 0;
  }
}
</style>
