<template>
  <div class="sticky-input bg-transparent row p-0 m-0">
    <div class="col-12 mb-1 p-0">
      <!-- Dropdown: Stores -->
      <b-dropdown
        class="filter-dropdown"
        variant="outline-none"
        block
        no-caret
        menu-class="w-100 filter-dropdown-menu scroll-bar"
        toggle-class="filter-dropdown-toggle"
        no-flip
      >
        <template #button-content>
          <div
            class="d-flex justify-content-between"
            :class="{ 'has-value': !!selectedStore.storeName }"
          >
            <span> {{ selectedStore.storeName || 'Select store' }} </span>
            <feather-icon icon="ChevronDownIcon" />
          </div>
        </template>
        <b-dropdown-text>
          <b-form-input
            placeholder="Search"
            class="filter-dropdown-toggle m-0"
            @input="onSearchStores"
          />
          <div
            v-if="!filteredStores.length"
            class="py-2 text-center"
          >
            Nothing was found. Try another store name.
          </div>
        </b-dropdown-text>
        <b-dropdown-item
          v-for="store in filteredStores"
          :key="store.storeId"
          :active="selectedStore.storeId === store.storeId"
          @click="onSelectedStore(store)"
        >
          <div>
            <span>
              {{ store.storeName }}
            </span>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="col-8 p-0">
      <!-- Search products -->
      <b-form-input
        placeholder="Search by products, style code..."
        class="m-0"
        @input="onSearchCartProducts"
      />
      <feather-icon
        icon="SearchIcon"
        size="18"
        class="cart-search-icon"
      />
    </div>
    <div class="col-12 mt-1 col-lg-4 mt-lg-0 p-0 d-flex justify-content-end">
      <!-- Cart buttons -->
      <b-button
        class="mr-1"
        variant="outline-danger clear-cart-btn"
        :disabled="isSavingCart"
        @click="onClickClearCart"
      >
        <shopping-bag-deletete-icon class="align-bottom" />
        Clear Cart
      </b-button>
      <b-button
        variant="outline-secondary-black"
        :disabled="isSavingCart"
        @click="onClickSaveCart"
      >
        Save Cart
      </b-button>
    </div>
  </div>
</template>

<script>
import ShoppingBagDeleteteIcon from '@/@core/assets/svg-components/ShoppingBagDeleteteIcon.vue'
import {
 BButton, BDropdown, BDropdownItem, BDropdownText, BFormInput, VBModal,
} from 'bootstrap-vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'

const CART_WITH_MULTIPLE_STORES = constants.TRACKS.ACTIONS.CART_WITH_MULTIPLE_STORES

const defaultStore = {
    storeName: 'All stores',
    storeId: null,
}
export default {
    name: 'CartSearchSaveClearSection',
    components: {
        BButton,
        BFormInput,
        BDropdown,
BDropdownText,
BDropdownItem,
        ShoppingBagDeleteteIcon,
    },
    directives: {
        'v-b-modal': VBModal,
    },
    props: {
        cartList: {
            type: Array,
            required: true,
        },
        isSavingCart: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            stores: [defaultStore],
            filteredStores: [],
            selectedStore: defaultStore,
        }
    },
    created() {
        const stores = this.cartList.map(cart => ({ storeName: cart?.storeName, storeId: cart?._id }))
        this.stores = [...this.stores, ...stores]
        this.filteredStores = this.stores
    },
    emits: ['on-selected-store', 'on-search-cart-products', 'on-click-save-cart', 'on-click-clear-cart'],
    methods: {
      onSearchStores(searchText) {
        if (!searchText) {
          this.filteredStores = this.stores
          return
        }
        this.filteredStores = this.stores.filter(store => store.storeName.toLowerCase().includes(searchText.toLowerCase()))
      },
        onSelectedStore(store) {
            this.selectedStore = store
            this.$emit('on-selected-store', store.storeId)
            analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_CLICKED_FILTER_STORES_DROPDOWN, { selectedStore: store })
        },
        onSearchCartProducts(searchText) {
            this.$emit('on-search-cart-products', searchText)
        },
        onClickSaveCart() {
            this.$emit('on-click-save-cart')
        },
        onClickClearCart() {
            this.$emit('on-click-clear-cart')
        },

    },

}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-cart-products.scss';
</style>
