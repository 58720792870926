<template>
  <div class="font-inter">
    <!-- Cancelled products -->
    <div
      v-if="showCancelledErrorToast"
      class="cart-error-toast-container mb-1"
    >
      <feather-icon
        class="cursor-pointer x-icon"
        icon="XIcon"
        size="20"
        @click="onClickCrossIcon(ERRORS.CANCELLED)"
      />

      <div class="h-100 d-flex justify-content-between align-items-center">
        <div class="h-100 d-flex align-items-center ml-1">
          <alert-icon-svg />
          <div class="ml-2">
            <div class="error-title">
              {{ errorTitle[ERRORS.CANCELLED] }}
            </div>
            <span class="error-desscription">Remove them from the cart. After the removal you can proceed with the order.</span>
          </div>
        </div>
        <div class="mr-3">
          <div
            class="remove-btn"
            @click="removeAllCancelledItems"
          >
            <b-spinner
              v-if="isRemovingCancelledItems"
              small
            />
            <span v-else>
              Remove all cancelled items
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Out of stock products -->
    <div
      v-if="showOutOfStockErrorToast"
      class="cart-error-toast-container mb-1"
    >
      <feather-icon
        class="cursor-pointer x-icon"
        icon="XIcon"
        size="20"
        @click="onClickCrossIcon(ERRORS.OUT_OF_STOCK)"
      />

      <div class="h-100 d-flex justify-content-between align-items-center">
        <div class="h-100 d-flex align-items-center ml-1">
          <alert-icon-svg />
          <div class="ml-2">
            <div class="error-title">
              {{ errorTitle[ERRORS.OUT_OF_STOCK] }}
            </div>
            <span class="error-desscription">Remove them from the cart. After the removal you can proceed with the order.</span>
          </div>
        </div>
        <div class="mr-3">
          <div
            class="remove-btn"
            @click="removeAllOutOfStockItems"
          >
            <b-spinner
              v-if="isRemovingOutOfStockItems"
              small
            />
            <span v-else>
              Remove all out of stock items
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Zero quantity products -->
    <div
      v-if="showZeroQtyErrorToast"
      class="cart-error-toast-container mb-1"
    >
      <feather-icon
        class="cursor-pointer x-icon"
        icon="XIcon"
        size="20"
        @click="onClickCrossIcon(ERRORS.ZERO_QTY)"
      />
      <div class="h-100 d-flex justify-content-between align-items-center">
        <div class="h-100 d-flex align-items-center ml-1">
          <alert-icon-svg />
          <div class="ml-2">
            <div class="error-title">
              {{ errorTitle[ERRORS.ZERO_QTY] }}
            </div>
            <span class="error-desscription">Remove them from the cart. After the removal you can proceed with the order.</span>
          </div>
        </div>
        <div class="mr-3">
          <div
            class="remove-btn"
            @click="removeAllZeroQtyItems"
          >
            <b-spinner
              v-if="isRemovingZeroQtyItems"
              small
            />
            <span v-else>
              Remove all items with 0 quantities
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlertIconSvg from '@/@core/assets/svg-components/AlertIconSvg.vue'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import {
 DELETE_PRODUCTS_BY_CART_ID, LOAD_CARTS, PUSH_EXPANDED_CARTS, UPDATE_CART_ERRORS,
} from '@/store/modules/checkout-v2.module'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import { BSpinner } from 'bootstrap-vue'

const { PRODUCT_STATUS } = c

const ERRORS = {
    CANCELLED: 'cancelled',
    OUT_OF_STOCK: 'outofstock',
    ZERO_QTY: 'zeroqty',
}

export default {
    name: 'CartErrors',
    components: { AlertIconSvg, BSpinner },
    props: {
      cartId: {
        type: String,
        default: '',
      },
      cartProducts: {
        type: Array,
        default: () => [],
      },
    },
    data() {
        return {
            cancelledItemsCount: 0,
            outOfStockItemsCount: 0,
            zeroQtyItemsCount: 0,
            showCancelledErrorToast: false,
            showOutOfStockErrorToast: false,
            showZeroQtyErrorToast: false,
            cancelledProductIds: [],
            outOfStockProductIds: [],
            zeroQtyProductIds: [],
            isRemovingCancelledItems: false,
            isRemovingOutOfStockItems: false,
            isRemovingZeroQtyItems: false,
            ERRORS,
        }
    },
    computed: {
      errorTitle() {
        return {
          [ERRORS.CANCELLED]: this.cancelledItemsCount > 1 ? `${this.cancelledItemsCount} items are cancelled.` : `${this.cancelledItemsCount} item is cancelled.`,
          [ERRORS.OUT_OF_STOCK]: this.outOfStockItemsCount > 1 ? `${this.outOfStockItemsCount} items are out of stock.` : `${this.outOfStockItemsCount} item is out of stock.`,
          [ERRORS.ZERO_QTY]: this.zeroQtyItemsCount > 1 ? `${this.zeroQtyItemsCount} items have 0 quantities.` : `${this.zeroQtyItemsCount} item has 0 quantities.`,
        }
      },
    },
    watch: {
        cartProducts: {
            handler() {
                this.loadErrors()
            },
            deep: true,
        },
    },
    created() {
        this.loadErrors()
    },
    methods: {
        loadErrors() {
          this.resetCounters()
          this.cartProducts.forEach(product => {
            if (product.status === PRODUCT_STATUS.CANCELLED.VALUE) {
              this.cancelledItemsCount++
              this.cancelledProductIds.push(product.productId)
            } else if (product?.hasError) {
              this.outOfStockItemsCount++
              this.outOfStockProductIds.push(product.productId)
            } else if (!product?.lineTotalQuantity) {
              this.zeroQtyItemsCount++
              this.zeroQtyProductIds.push(product.productId)
            }
          })
            this.toggleErrorToast()
        },
        resetCounters() {
            this.cancelledItemsCount = 0
            this.outOfStockItemsCount = 0
            this.zeroQtyItemsCount = 0
            this.cancelledProductIds = []
            this.outOfStockProductIds = []
            this.zeroQtyProductIds = []
        },
        resetLoaders() {
            this.isRemovingCancelledItems = false
            this.isRemovingOutOfStockItems = false
            this.isRemovingZeroQtyItems = false
        },
        toggleErrorToast() {
            this.showCancelledErrorToast = !!this.cancelledItemsCount
            this.showOutOfStockErrorToast = !!this.outOfStockItemsCount
            this.showZeroQtyErrorToast = !!this.zeroQtyItemsCount
            const isCartHasAnError = this.showCancelledErrorToast || this.showOutOfStockErrorToast || this.showZeroQtyErrorToast
            if (isCartHasAnError)
              {
                this.$store.commit(PUSH_EXPANDED_CARTS, this.cartId)
              }
        },
        removeAllCancelledItems() {
            if (this.isRemovingCancelledItems) return
            this.isRemovingCancelledItems = true
            this.removeMultipleProducts(this.cancelledProductIds)
        },
        removeAllOutOfStockItems() {
            if (this.isRemovingOutOfStockItems) return
            this.isRemovingOutOfStockItems = true
            this.removeMultipleProducts(this.outOfStockProductIds)
        },
        removeAllZeroQtyItems() {
            if (this.isRemovingZeroQtyItems) return
            this.isRemovingZeroQtyItems = true
            this.removeMultipleProducts(this.zeroQtyProductIds)
        },
        removeMultipleProducts(productIds = []) {
            this.$store.dispatch(DELETE_PRODUCTS_BY_CART_ID, { cartId: this.cartId, productIds }).then(res => {
                apiToastSuccess(res.data.message)
                this.$store.dispatch(LOAD_CARTS)
                this.$store.commit(UPDATE_CART_ERRORS)
                this.resetLoaders()
            }).catch(err => {
                apiToastError(err)
            })
        },
        onClickCrossIcon(type) {
            switch (type) {
                case ERRORS.CANCELLED:
                    this.showCancelledErrorToast = false
                    break
                case ERRORS.OUT_OF_STOCK:
                    this.showOutOfStockErrorToast = false
                    break
                case ERRORS.ZERO_QTY:
                    this.showZeroQtyErrorToast = false
                    break
                default:
                    break
            }
        },
    },
}
</script>

<style lang="scss" scoped>
$error-color: #AF504B;

.cart-error-toast-container {
    width: 100%;
    height: 69px;
    background: #FFD9D6;
    color: $error-color;
    border: 3px $error-color solid;
    position: relative;

    .x-icon {
        position: absolute;
        top: 8px;
        right: 8px;
    }

    .error-title {
        color: $error-color;
        font-size: 20px;
        font-weight: 600;
        word-wrap: break-word;
    }

    .error-desscription {
        color: $error-color;
        font-size: 14px;
        font-weight: 400;
        word-wrap: break-word
    }

    .remove-btn {
        height: 36px;
        padding-left: 16px;
        padding-right: 16px;
        background: $error-color;
        justify-content: center;
        align-items: center;
        gap: 8px;
        display: inline-flex;

        text-align: center;
        color: #F4F4F4;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        word-wrap: break-word;
        cursor: pointer;

        &:hover {
            opacity: 0.95;
        }
    }
}
</style>
