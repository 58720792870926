<template>
  <b-modal
    :id="CART_EXPORT_MODAL_ID"
    hide-header
    hide-header-close
    hide-footer
    size="md"
    centered
    @hide="resetData"
  >
    <div class="p-1">
      <div class="pb-1 d-flex justify-content-between align-items-center">
        <div class="h2 font-weight-bold">
          Export cart
        </div>
        <feather-icon
          class="float-right close-x-icon"
          icon="XIcon"
          size="24"
          @click="closePopup"
        />
      </div>
      <div class="text-primary font-weight-bold h4">
        Select a file format
      </div>
      <div class="row mx-0">
        <div
          v-for="fileFormat of DOWNLOAD_OPTIONS.CART"
          :key="fileFormat.value"
          class="col-4 p-25"
          :class="`col-${12 / DOWNLOAD_OPTIONS.CART.length}`"
          @click="onSelectType(fileFormat)"
        >
          <div
            class="download-option d-flex flex-column justify-content-center align-items-center rounded"
            :class="{ 'active': downloadType && downloadType.value === fileFormat.value }"
          >
            <component :is="getComponent(fileFormat.fileType)" />
            <small class="mt-1">{{ fileFormat.text }}</small>
          </div>
        </div>
      </div>

      <div
        v-if="!isDownloadTypeXlsx"
        class="m-1"
      >
        <div class="mb-50 font-inter">
          Set {{ downloadType.text }} options:
        </div>
        <b-form-checkbox-group
          v-model="selectedOptions"
          stacked
          :options="options"
        />
      </div>

      <div class="w-100 mt-2 d-flex justify-content-center">
        <div>
          <b-button
            variant="outline-primary"
            @click="closePopup"
          >
            Cancel
          </b-button>
        </div>
        <div class="ml-1">
          <b-button
            variant="primary"
            :disabled="!downloadType || isExporting"
            @click="onClickDownload"
          >
            {{ isExporting ? 'Downloading...' : 'Download' }}
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

import XlsxIcon from '@/@core/assets/svg-components/XlsxIcon.vue'
import constants, { CART_EXPORT_MODAL_ID, DOWNLOAD_TYPES, FILE_FORMATS } from '@/constants'
import PdfIcon from '@/@core/assets/svg-components/PdfIcon.vue'
import ZipIcon from '@/@core/assets/svg-components/ZipIcon.vue'
import PptxIcon from '@/@core/assets/svg-components/PptxIcon.vue'
import { BButton, BFormCheckboxGroup, BModal } from 'bootstrap-vue'
import { EXPORT_CART } from '@/store/modules/shop.module'
import analytics from '@/@core/utils/analytics'
import { formatObject } from '@/@core/utils/utils'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import UserRoleMixin from '../UserRoleMixin.vue'

const { DOWNLOAD_OPTIONS } = constants

export default {
    name: 'CartExportModal',
    components: {
        BModal,
        BButton,
        BFormCheckboxGroup,
    },
    mixins: [UserRoleMixin],
    props: {
        retailerId: {
            type: String,
            default: null,
        },
        isMultipleCart: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            downloadType: DOWNLOAD_OPTIONS.CART[0],
            DOWNLOAD_OPTIONS,
            CART_EXPORT_MODAL_ID,
            options: [
                { text: 'WSP', value: 'wsp' },
                { text: 'RRP', value: 'rrp' },
                { text: 'Gender', value: 'gender' },
                { text: 'Material', value: 'material' },
                { text: 'Size', value: 'size' },
            ],
            selectedOptions: [],
            isExporting: false,
        }
    },
    emits: ['on-cart-export-success', 'open-export-multiple-carts-modal'],
    computed: {
        isDownloadTypeXlsx() {
            return this.downloadType?.value === FILE_FORMATS.XLSX
        },
    },
    methods: {
        // On select option
        onSelectType(fileFormat) {
            this.downloadType = fileFormat
            this.selectedOptions = []
        },
        // On click cancel
        resetData() {
            this.selectedOptions = []
            this.downloadType = DOWNLOAD_OPTIONS.CART[0]
            this.isExporting = false
        },
        // On click download
        onClickDownload() {
            const exportType = this.downloadType?.value
            if (!exportType) {
                apiToastWarning('Please select a file format')
                return
            }
            if (this.isMultipleCart) {
                this.$emit('open-export-multiple-carts-modal', { exportType, selectedOptions: this.selectedOptions })
                this.closePopup()
                return
            }
            this.isExporting = true
            const payload = {
                downloadType: DOWNLOAD_TYPES.CART_EXPORT.VALUE,
                fileType: exportType,
            }
            if (exportType !== FILE_FORMATS.XLSX) {
                payload.respectiveId = this.$store.getters.getCart._id
            }
            if (this.selectedOptions?.length) {
                payload.optionalFields = this.selectedOptions
            }
            const params = this.retailerId ? { retailerId: this.retailerId } : {}
            this.$store.dispatch(EXPORT_CART, { payload, params })
                .then(res => {
                    this.$bvModal.hide(CART_EXPORT_MODAL_ID)
                    apiToastSuccess(res.data.message)
                    this.$emit('on-cart-export-success')
                })
                .catch(err => {
                    apiToastWarning(err)
                }).finally(() => {
                    this.isExporting = false
                })
                this.handleEvents(exportType)
        },
        handleEvents(exportType) {
          let trackSubject = {}
            let trackObject = {}
            if (this.isRetailer) {
                trackObject = formatObject({ exportFileFormat: exportType })
                trackSubject = constants.TRACKS.ACTIONS.RETAILER_EXPORTS_CART
            } else {
                trackObject = formatObject({ exportFileFormat: exportType, retailerId: this.retailerId })
                trackSubject = constants.TRACKS.ACTIONS.BRAND_EXPORTS_CART_FOR_RETAILER
            }
            analytics.track(trackSubject, trackObject)
        },
        getComponent(format) {
            const formatLower = format?.toLowerCase()
            if (formatLower.includes(FILE_FORMATS.PDF)) {
                return PdfIcon
            }
            switch (formatLower) {
                case FILE_FORMATS.PDF.toLowerCase():
                    return PdfIcon
                case FILE_FORMATS.XLSX.toLowerCase():
                    return XlsxIcon
                case FILE_FORMATS.ZIP.toLowerCase():
                    return ZipIcon
                case FILE_FORMATS.PPTX.toLowerCase():
                    return PptxIcon
                default:
                    return XlsxIcon
            }
        },
        closePopup() {
            this.resetData()
            this.$bvModal.hide(CART_EXPORT_MODAL_ID)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/@core/scss/base/bootstrap-extended/_variables.scss";

.download-option {
    cursor: pointer;
    height: 100px;
    background-color: $gray-200;

    &:hover,
    &.active {
        border: 1px solid $black;
    }
}
</style>
