<template>
  <div class="checkout-options mt-2">
    <b-card class="mb-0 cart-order-details-card border-0">
      <div class="price-details">
        <div class="price-title mb-0 row">
          <div
            class="col-2 col-sm-12 col-md-2 cursor-pointer d-flex align-items-center color-neutral-black"
            @click="onClickOrderDetailsToggle"
          >
            Order details
          </div>
          <div class="col-sm-12 col-md-10 d-flex justify-content-end align-items-center">
            <div
              v-if="!orderDetailsCartVisible"
              class="d-flex align-items-center"
            >
              <!-- Total costs -->
              <div class="price-detail mb-0">
                <div class="detail-title">
                  Total:
                </div>
                <div class="text-primary ml-1 text-nowrap">
                  {{ formatCurrency(Number(totalOrderValue.totalCartsValue), totalOrderValue.currency) }}
                </div>
              </div>
              <!-- Place order button -->
              <place-order-button
                class="mx-1 text-nowrap"
                :is-terms-accepted="isTermsAccepted"
                :is-placing-order="isPlacingOrder"
                @on-click-place-order="onPlaceOrderForAllStores"
                @on-click-select-stores-and-place-order="onClickSelectStoresAndPlaceOrder"
              />
            </div>

            <feather-icon
              :icon="orderDetailsCartVisible ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              size="24"
              @click="onClickOrderDetailsToggle"
            />
          </div>
        </div>
      </div>
      <b-collapse
        id="order_details_collapse"
        v-model="orderDetailsCartVisible"
      >
        <div class="price-details mt-1">
          <ul class="list-unstyled mb-0">
            <li class="price-detail">
              <div class="detail-title">
                Total units
              </div>
              <div class="detail-amt">
                {{ formatNumber(totalOrderValue.totalCartsUnits) || 0 }}
              </div>
            </li>
            <!-- Total costs -->
            <li class="price-detail">
              <div class="detail-title">
                Total costs
              </div>
              <div class="detail-amt">
                {{ formatCurrency(Number(totalOrderValue.totalCartsValue), totalOrderValue.currency) }}
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                Total RRP
              </div>
              <div class="detail-amt">
                {{ formatCurrency(Number(totalOrderValue.totalCartsRRP), totalOrderValue.currency) }}
              </div>
            </li>
            <!-- TODO: Here need to add the code for Apply credits -->
          </ul>
          <!-- horizontal line -->
          <hr class="mt-0 border-bottom-secondary">
          <ul class="list-unstyled mb-0">
            <li class="price-detail">
              <div class="total-price">
                Total
              </div>
              <div class="total-price">
                {{ formatCurrency(Number(totalOrderValue.totalCartsValue), totalOrderValue.currency) }}
              </div>
            </li>
          </ul>
        </div>
        <div class="mt-1">
          <!-- Cart terms -->
          <div class="my-50">
            <div class="d-flex">
              <b-form-checkbox
                v-model="isTermsAccepted"
                class="terms-checkbox"
              >
                I Agree with
              </b-form-checkbox>
              <span @click="$bvModal.show('cart-terms')"> <u class="color-actions-text-grey cursor-pointer ml-25"> terms
                and
                conditions </u> </span>
            </div>
            <cart-terms @update-terms="updateTerms" />
          </div>
          <div class="mt-1">
            <!-- Place order button -->
            <place-order-button
              :is-terms-accepted="isTermsAccepted"
              :is-placing-order="isPlacingOrder"
              @on-click-place-order="onPlaceOrderForAllStores"
              @on-click-select-stores-and-place-order="onClickSelectStoresAndPlaceOrder"
            />
          </div>
        </div>
      </b-collapse>
    </b-card>

    <select-stores-place-order-modal
      :modal-id="selectStoresModalId"
      :cart-values-list="cartValuesList"
      @on-click-place-order="onPlaceOrderWithSelectedStores"
    />
  </div>
</template>

<script>
import {
 BCard, BCollapse, BFormCheckbox,
} from 'bootstrap-vue'
import { utils, constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { formatNumber } from '@/@core/utils/utils'

import { CREATE_MULTIPLE_ORDER } from '@/store/modules/order.module'
import { apiToastError, apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { UPDATE_CART_ERRORS, UPDATE_CARTS, LOAD_CARTS } from '@/store/modules/checkout-v2.module'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import PlaceOrderButton from './components/PlaceOrderButton.vue'
import CartTerms from '../checkout/CartTerms.vue'
import SelectStoresPlaceOrderModal from './SelectStoresPlaceOrderModal.vue'

const { formatCurrency } = utils

const { CURRENCY_CONVERSIONS } = c

const selectStoresModalId = 'select_stores_place_order_popup'

const CART_WITH_MULTIPLE_STORES = constants.TRACKS.ACTIONS.CART_WITH_MULTIPLE_STORES

export default {
  name: 'CartsTotal',
  components: {
    BCard,
    BFormCheckbox,
    CartTerms,
    BCollapse,
    PlaceOrderButton,
    SelectStoresPlaceOrderModal,
  },
  props: {
    cartList: {
      type: Array,
      required: true,
    },
    cartValuesList: {
      type: Array,
      required: true,
    },
    totalOrderValue: {
      type: Object,
      default: () => ({
        totalCartsValue: 0,
        totalCartsUnits: 0,
        totalCartsRRP: 0,
        currency: CURRENCY_CONVERSIONS.USD.SYMBOL,
      }),
    },
    onPlaceOrderSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isTermsAccepted: false,
      isPlacingOrder: false,
      orderDetailsCartVisible: true,
      selectStoresModalId,
      formatCurrency,
      formatNumber,
    }
  },
  methods: {
    onClickOrderDetailsToggle() {
      this.orderDetailsCartVisible = !this.orderDetailsCartVisible
      analytics.track(this.orderDetailsCartVisible ? CART_WITH_MULTIPLE_STORES.RETAILER_EXPANDS_ORDER_DETAILS_CARD : CART_WITH_MULTIPLE_STORES.RETAILER_COLLAPSES_ORDER_DETAILS_CARD, { totalOrderValue: this.totalOrderValue })
    },
    updateTerms() {
      this.isTermsAccepted = true
      this.$bvModal.hide('cart-terms')
    },
    onClickSelectStoresAndPlaceOrder() {
      this.$bvModal.show(selectStoresModalId)
    },
    onPlaceOrderForAllStores() {
      const storeIds = this.cartList.map(cart => cart._id)
      analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_CLICKED_PLACE_ORDER_FOR_ALL_STORES_BUTTON, { carts: this.cartValuesList, storeIds })
      this.placeOrder(storeIds)
    },
    onPlaceOrderWithSelectedStores(storeIds = []) {
      if (!storeIds.length) {
        apiToastWarning('Please select at least one store to place the order')
        return
      }
      this.$bvModal.hide(selectStoresModalId)
      analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_CLICKED_SELECT_STROES_AND_PLACE_ORDER_BUTTON, { carts: this.cartValuesList, storeIds })
      this.placeOrder(storeIds)
    },
    async placeOrder(storeIds = []) {
      try {
        this.startPlacingOrder()
        await this.updateCarts()
        const res = await this.createMultipleOrders(storeIds)
        this.handleOrderSuccess(res, storeIds)
      } catch (err) {
        this.handleOrderError(err)
      } finally {
        this.finishPlacingOrder()
      }
    },
    startPlacingOrder() {
      this.isPlacingOrder = true
    },
    finishPlacingOrder() {
      this.isPlacingOrder = false
    },
    async updateCarts() {
      await this.$store.dispatch(UPDATE_CARTS)
    },
    async createMultipleOrders(storeIds) {
      return await this.$store.dispatch(CREATE_MULTIPLE_ORDER, { cartIds: storeIds })
    },
    handleOrderSuccess(res, storeIds) {
      const message = res.data.message || 'Order placed successfully'
      apiToastSuccess(message)
      analytics.track(CART_WITH_MULTIPLE_STORES.ORDER_PLACED_SUCCESSFULLY, { carts: this.cartValuesList, cartIds: storeIds })
      this.onPlaceOrderSuccess()
      this.setOrderSuccessPopupContext(res?.data?.data)
    },
    setOrderSuccessPopupContext(data) {
      this.$root.$orderSuccessPopupContext.value = {
        orderIds: data || [],
      }
    },
    handleOrderError(err) {
      const errorData = err?.response?.data?.data
      const succeededOrders = errorData?.succeededOrders ?? []
      if (succeededOrders.length) {
        this.setOrderSuccessPopupContext(succeededOrders)
      }
      const failedStoreOrders = errorData?.failedStoreOrders ?? []
      if (failedStoreOrders.length) {
        this.showFailedStoreOrdersErrors(failedStoreOrders)
        this.$store.commit(UPDATE_CART_ERRORS)
        this.$store.dispatch(LOAD_CARTS)
      } else {
        apiToastError(err)
      }
      analytics.track(CART_WITH_MULTIPLE_STORES.GETTING_ERROR_WHILE_PLACING_ORDER, err?.response)
    },
    showFailedStoreOrdersErrors(failedStoreOrders) {
      failedStoreOrders.forEach(errObj => {
        apiToastError(`${errObj?.storeName} - ${errObj?.error}`)
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss">
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";

.cart-order-details-card {
  background-color: $gray-200;

  .card-body {
    padding: 1rem;
  }
  .price-details {
  .price-title {
    font-size: 1.25rem;
  }
}
}

</style>
