<template>
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.33325 28.5001H30.6666L15.9999 3.16675L1.33325 28.5001ZM17.3333 24.5001H14.6666V21.8334H17.3333V24.5001ZM17.3333 19.1667H14.6666V13.8334H17.3333V19.1667Z"
      fill="#AF504B"
    />
  </svg>
</template>

<script>
export default {
    name: 'AlertIconSvg',
}
</script>
