<template>
  <b-card class="mb-50 p-0 address-details-card border-0">
    <div class="row">
      <div class="col-9">
        <div class="row">
          <div class="col-12 label-text color-neutral-black">
            Shipping address
          </div>
        </div>
        <!-- Shipping Address -->
        <div
          v-if="selectedShippingAddress"
          class="row mt-50 address-value"
        >
          <div class="col-6">
            <div>{{ selectedShippingAddress.contactName }},</div>
            <div>{{ selectedShippingAddress.addressLine1 }}, {{ selectedShippingAddress.addressLine2 }},</div>
            <div>
              {{ selectedShippingAddress.city }}, {{ selectedShippingAddress.country }} - <span>{{ selectedShippingAddress.zipcode }}</span>
            </div>
          </div>
          <div class="col-6">
            <div>Logistic Contact: {{ selectedShippingAddress.contactNumber }}</div>
            <div class="text-lowercase">
              {{ selectedShippingAddress.contactEmail }}
            </div>
          </div>
        </div>
        <div v-else>
          -
        </div>
        <!-- Notes -->
        <div class="row mt-1">
          <div class="col-6">
            <div>
              <span class="label-text color-neutral-black">
                Notes
              </span>
              <b-button
                v-if="isEditNotes"
                class="ml-25"
                size="sm"
                :disabled="isSavingNotes"
                variant="custom-primary"
                @click="onUpdateNotes"
              >
                {{ isSavingNotes ? 'Saving' : 'Save' }}
              </b-button>
              <span
                v-if="!isEditNotes"
                class="color-actions-text-grey ml-10"
              >
                <feather-icon
                  icon="EditIcon"
                  size="15"
                  class="edit-icon cursor-pointer"
                  @click="isEditNotes = true"
                />
              </span>
            </div>
            <div class="mt-50">
              <b-form-textarea
                v-if="isEditNotes"
                v-model="cart.notes"
                placeholder="Enter Notes"
              />
              <span
                v-else
                class="color-actions-text-grey"
              >{{ cart.notes || '-' }}</span>
            </div>
          </div>
        </div>

      </div>
      <!-- Select address button -->
      <div class="col-3 d-flex justify-content-end">
        <div v-b-tooltip.hover="!selectedShippingAddress ? 'No address found' : ''">
          <b-button
            variant="outline-primary"
            :disabled="!selectedShippingAddress"
            @click="onClickSelectAddress"
          >
            Select Another Address
          </b-button>
        </div>
        <b-modal
          :id="'select_address_modal' + cart._id"
          size="lg"
          centered
          title="Select Address"
          hide-footer
        >
          <address-list
            class="mb-1"
            :address-type="ADDRESSES_TYPE.SHIPPING"
            :is-popup="true"
            :is-multiple-cart="true"
            :address="shippingAddress"
            :commons="countryData"
            :set-as-primary-address="setAsPrimaryAddress"
          />
        </b-modal>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
 BButton, BCard, BFormTextarea, BModal, VBTooltip,
} from 'bootstrap-vue'
import { UPDATE_CART_NOTES } from '@/store/modules/shop.module'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { mapState } from 'vuex'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import { GET_COUNTRIES } from '@/store/modules/common.module'
import AddressList from '../account-setting/multiple-address/AddressList.vue'
import AddressMixin from '../account-setting/multiple-address/AddressMixin.vue'

const { ADDRESSES_TYPE } = c

const CART_WITH_MULTIPLE_STORES = constants.TRACKS.ACTIONS.CART_WITH_MULTIPLE_STORES

export default {
  name: 'AddressSection',
  components: {
    BCard,
    BButton,
    BFormTextarea,
    BModal,
    AddressList,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [AddressMixin],
  props: {
    cart: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isEditNotes: false,
      isSavingNotes: false,
      selectedShippingAddress: null,
      ADDRESSES_TYPE,
      addresses: [],
      countryData: null,
    }
  },
  computed: {
    ...mapState({
      commons: state => state.common.commonData,
    }),
  },
  created() {
    this.loadCountries()
    this.userId = this.cart?.userId
    this.selectAddressModalId = `select_address_modal${this.cart._id}`
    this.addresses = this.cart?.addresses || []
    if (this.addresses) {
      this.loadShippingAddress()
    }
  },
  methods: {
    loadCountries() {
      this.$store.dispatch(GET_COUNTRIES).then(res => {
        this.countryData = res?.data?.data
      }).catch(err => {
        apiToastError(err)
      })
    },
    onClickSelectAddress() {
      analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_SELECT_ANOTHER_ADDRESS_BUTTON, { shippingAddress: this.shippingAddress })
      this.$bvModal.show(this.selectAddressModalId)
    },
    async onUpdateNotes() {
      this.isSavingNotes = true
      this.$store.dispatch(UPDATE_CART_NOTES, { payload: { cartId: this.cart._id, notes: this.cart.notes } }).then(res => {
        this.isSavingNotes = false
        this.isEditNotes = false
        apiToastSuccess(res.data.message)
      }).catch(err => {
        this.isSavingNotes = false
        apiToastError(err)
     })
    },
    loadShippingAddress() {
      this.selectedShippingAddress = this.addresses.find(address => address.addressType === ADDRESSES_TYPE.SHIPPING && address.isPrimary)
      this.getBillingAndShippingAddress(this.addresses)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";

.address-details-card {
  background-color: $gray-200;

  .label-text {
    font-size: 1rem;
    font-weight: 700;
  }

  .address-value {
    color: $actions-text-grey
  }
}
</style>
