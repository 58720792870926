<template>
  <b-modal
    id="clear_carts"
    centered
    hide-footer
    hide-header
    hide-header-close
  >
    <div
      class="
          pb-5-px
          border-bottom
          d-flex
          justify-content-between
          align-items-center
        "
    >
      <div class="elements-center">
        <span class="modal-rounded-icon">
          <shopping-bag-deletete-icon />
        </span>
        <span class="custom-modal-heading color-primary">
          Clear my cart
        </span>
      </div>
      <feather-icon
        class="float-right color-neutral-black cursor-pointer"
        icon="XIcon"
        size="24"
        @click="$bvModal.hide('clear_carts')"
      />
    </div>
    <div class="mt-1">
      <b-card-text class="mb-1">
        The cart will be permanently cleared
      </b-card-text>
      <b-card-text> Are you sure you want to proceed? </b-card-text>
      <div class="elements-center my-1">
        <b-button
          variant="outline-secondary-black mr-1"
          @click="$bvModal.hide('clear_carts')"
        >
          Cancel
        </b-button>
        <b-button
          variant="danger"
          @click="$emit('on-click-clear-cart')"
        >
          Yes, clear it
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BButton, BCardText, BModal } from 'bootstrap-vue'
import ShoppingBagDeleteteIcon from '@/@core/assets/svg-components/ShoppingBagDeleteteIcon.vue'

export default {
    name: 'ClearCartsModal',
    components: {
        BModal, BButton, BCardText, ShoppingBagDeleteteIcon,
    },
    emits: ['on-click-clear-cart'],
}
</script>
