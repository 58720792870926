<template>
  <!-- Buying Summary Modal -->
  <b-modal
    id="buying_summary_v_2"
    hide-header
    hide-header-close
    hide-footer
    size="xl"
    centered
  >
    <div class="pb-1 border-bottom d-flex justify-content-between align-items-center">
      <div class="custom-modal-heading">
        Buying Summary
      </div>
      <div class="d-flex align-items-center">
        <div class="d-lg-flex align-items-center mr-1">
          <b-badge class="p-50 values-badge color-neutral-black">
            Total units: {{ formatNumber(totalOrderValue.totalCartsUnits) || 0 }}
          </b-badge>
          <b-badge class="p-50 values-badge color-neutral-black">
            Total cost: {{ formatCurrency(Number(totalOrderValue.totalCartsValue), totalOrderValue.currency) }}
          </b-badge>
          <b-badge class="p-50 values-badge color-neutral-black">
            Total RRP: {{ formatCurrency(Number(totalOrderValue.totalCartsRRP), totalOrderValue.currency) }}
          </b-badge>
        </div>

        <div class="mr-1">
          <b-dropdown
            variant="outline-primary"
            small
            block
            no-caret
            no-flip
            right
          >
            <template #button-content>
              <div
                class="border-2 d-flex align-items-center justify-content-center"
              >
                <feather-icon
                  icon="SettingsIcon"
                  size="16"
                />
                <span class="ml-25">Filter</span>
              </div>
            </template>
            <b-dropdown-text>
              <div
                class="filter-options"
              >
                <b-card-text class="filter-heading">
                  Department
                </b-card-text>
                <b-form-checkbox-group
                  id="Department"
                  v-model="filteredProducts.Department"
                  name="Department"
                  class="mb-2"
                  stacked
                  @input="updateFilter('Department')"
                >
                  <b-form-checkbox
                    v-for="group in Object.keys(productGroups.Department)"
                    :key="group"
                    :value="group"
                    class="filter-checkbox"
                  >
                    <div class="checkbox-div">
                      <span>{{ group }}</span>
                      <span class="group-count"> {{ productGroups.Department[group] }} </span>
                    </div>
                  </b-form-checkbox>
                </b-form-checkbox-group>
                <b-card-text class="filter-heading">
                  Category
                </b-card-text>
                <b-form-checkbox-group
                  id="Category"
                  v-model="filteredProducts.Category"
                  name="Category"
                  class="mb-2"
                  stacked
                  @input="updateFilter('Category')"
                >
                  <b-form-checkbox
                    v-for="group in Object.keys(productGroups.Category)"
                    :key="group"
                    :value="group"
                    class="filter-checkbox"
                  >
                    <div class="checkbox-div">
                      <span>{{ group }}</span>
                      <span class="group-count"> {{ productGroups.Category[group] }} </span>
                    </div>
                  </b-form-checkbox>
                </b-form-checkbox-group>
                <b-card-text class="filter-heading">
                  SubCategory
                </b-card-text>
                <b-form-checkbox-group
                  id="SubCategory"
                  v-model="filteredProducts.SubCategory"
                  name="SubCategory"
                  class="mb-2"
                  stacked
                  @input="updateFilter('SubCategory')"
                >
                  <b-form-checkbox
                    v-for="group in Object.keys(productGroups.SubCategory)"
                    :key="group"
                    :value="group"
                    class="filter-checkbox"
                  >
                    <div class="checkbox-div">
                      <span>{{ group }}</span>
                      <span class="group-count"> {{ productGroups.SubCategory[group] }} </span>
                    </div>
                  </b-form-checkbox>
                </b-form-checkbox-group>
                <b-card-text class="filter-heading">
                  Gender
                </b-card-text>
                <b-form-checkbox-group
                  id="Gender"
                  v-model="filteredProducts.Gender"
                  name="Gender"
                  class="mb-2"
                  stacked
                  @input="updateFilter('Gender')"
                >
                  <b-form-checkbox
                    v-for="group in Object.keys(productGroups.Gender)"
                    :key="group"
                    :value="group"
                    class="filter-checkbox"
                  >
                    <div class="checkbox-div">
                      <span>{{ group }}</span>
                      <span class="group-count"> {{ productGroups.Gender[group] }} </span>
                    </div>
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </div>
            </b-dropdown-text>
          </b-dropdown>
        </div>

        <feather-icon
          class="float-right close-x-icon"
          icon="XIcon"
          size="24"
          @click="closeModal"
        />
      </div>
    </div>
    <div
      v-for="(cart, index) in cartList"
      :key="'buying_summary'+cart._id"
    >
      <buying-summary-v-2
        :cart="cart"
        :cart-value="cartValuesList[index]"
        :set-table-items="initBuyingSummary"
        :table-fields="tablesFields[index]"
      />
    </div>
  </b-modal>
</template>

<script>
import {
 BBadge, BCardText, BDropdown, BDropdownText, BFormCheckbox, BFormCheckboxGroup, BModal,
} from 'bootstrap-vue'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'

import { formatNumber } from '@/@core/utils/utils'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import BuyingSummaryV2 from './BuyingSummaryV2.vue'
import { filterOptionCount } from './buying-summary-utils'

const { formatCurrency } = utils

const { CURRENCY_CONVERSIONS } = c

export default {
    name: 'BuyingSummaryModal',
    components: {
        BBadge,
        BModal,
        BuyingSummaryV2,
        BCardText,
        BFormCheckboxGroup,
        BFormCheckbox,
        BDropdown,
        BDropdownText,
        FeatherIcon,
    },
    props: {
        cartList: {
            type: Array,
            required: true,
        },
        cartValuesList: {
            type: Array,
            required: true,
        },
        totalOrderValue: {
            type: Object,
            default: () => ({
                totalCartsValue: 0,
                totalCartsUnits: 0,
                totalCartsRRP: 0,
                currency: CURRENCY_CONVERSIONS.USD.SYMBOL,
            }),
        },
    },
    data() {
        return {
            formatCurrency,
            formatNumber,
            showFilter: false,
            filterIcon: require('@/assets/images/icons/filterIcon.svg'),
            activeFilterGroup: 'Department',
            productGroups: {
               Department: [],
               Category: [],
               SubCategory: [],
               Gender: [],
            },
            filteredProducts: {
                Department: [],
                Category: [],
                SubCategory: [],
                Gender: [],
            },
            tablesItems: [],
             tablesFields: [],
        }
    },
    methods: {
        closeModal() {
            this.$bvModal.hide('buying_summary_v_2')
        },
        initBuyingSummary(tableItems) {
            if (tableItems.length) {
                this.tablesItems.push(tableItems)
                this.tablesFields.push(this.filterProduct('', tableItems))
            }
        },
        filterProduct(group = '', tableItems = []) {
            // Filtering Product with user Input
            const filteringResult = {
                tableItems,
                Department: null,
                SubCategory: null,
                Category: null,
                Gender: null,
            }
            const keys = Object.keys(this.filteredProducts)
            const items = ['tableItems', 'Department', 'Category', 'SubCategory']
            keys.forEach((key, index) => {
                filteringResult[key] = filteringResult[items[index]].filter(item => this.filteredProducts[key].every(() => this.filteredProducts[key].includes(String(item[key]))))
                if (filteringResult[key].length === 0) filteringResult[key] = filteringResult[items[index]]
            })
            if (filteringResult.Gender.length === 0) filteringResult.Gender = tableItems
            // Setting Updated Filters

            const resultFilter = filterOptionCount(filteringResult.Gender)
            keys.forEach(key => {
              const isGroupNotDefined = !group
              const isKeyDifferentFromGroup = key !== group
              const hasNoFilteredProductsForKey = this.filteredProducts[key].length === 0
              const shouldUpdateProductGroup = isGroupNotDefined || (isKeyDifferentFromGroup && hasNoFilteredProductsForKey)
                if (shouldUpdateProductGroup) {
                  this.productGroups[key] = resultFilter[key]
                }
            })
            return filteringResult.Gender
        },
        updateFilter(group) {
            this.tablesItems.forEach((tableItems, index) => {
                this.tablesFields[index] = this.filterProduct(group, tableItems)
            })
        },

    },
}
</script>

<style lang="scss" scoped>
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";
  .values-badge {
    background-color: $gray-200;
    margin: 4px;
  }

  .filter-options {
    width: 350px !important;
    max-height: 500px;
    overflow: auto;
    padding-right: 14px;
  }
</style>
