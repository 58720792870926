<script>
import { mapState } from 'vuex'

export default {
    name: 'CartErrorsMixin',
    computed: {
        ...mapState({
            isCartHasErrors: state => state.checkout.isCartHasErrors,
        }),
    },
    watch: {
        isCartHasErrors: {
            handler(val) {
                if (val) {
                    this.initializeWithCartProducts(this.products, true)
                }
            },
            deep: true,
        },
    },
}
</script>
