<template>
  <b-modal
    :id="modalId"
    hide-header
    hide-header-close
    hide-footer
    size="lg"
    centered
  >
    <div class="pb-1 d-flex justify-content-between align-items-center">
      <div class="custom-modal-heading">
        Select stores & place order
      </div>
      <feather-icon
        class="float-right close-x-icon"
        icon="XIcon"
        size="24"
        @click="closeSelectStoresPopup"
      />
    </div>
    <div>

      <stores-list
        :cart-values-list="cartValuesList"
        @update-selected-stores="onSelectedStores"
      />

      <div class="checkout-options">
        <b-card class="mb-0 cart-order-details-card border-0 mt-1">
          <div class="price-details">
            <div class="price-title mb-0 row">
              <div class="col-12 color-neutral-black">
                Order details
              </div>
            </div>
          </div>
          <div class="price-details mt-1">
            <ul class="list-unstyled mb-0">
              <li class="price-detail">
                <div class="detail-title">
                  Total units
                </div>
                <div class="detail-amt">
                  {{ formatNumber(totalOrderValue.totalCartsUnits) || 0 }}
                </div>
              </li>
              <!-- Total costs -->
              <li class="price-detail">
                <div class="detail-title">
                  Total costs
                </div>
                <div class="detail-amt">
                  {{ formatCurrency(Number(totalOrderValue.totalCartsValue), totalOrderValue.currency) }}
                </div>
              </li>
              <li class="price-detail">
                <div class="detail-title">
                  Total RRP
                </div>
                <div class="detail-amt">
                  {{ formatCurrency(Number(totalOrderValue.totalCartsRRP), totalOrderValue.currency) }}
                </div>
              </li>
            </ul>
            <!-- horizontal line -->
            <hr class="mt-0 border-bottom-secondary">
            <ul class="list-unstyled mb-0">
              <li class="price-detail">
                <div class="total-price">
                  Total
                </div>
                <div class="total-price">
                  {{ formatCurrency(Number(totalOrderValue.totalCartsValue), totalOrderValue.currency) }}
                </div>
              </li>
            </ul>
          </div>
        </b-card>
      </div>

      <div class="w-100 my-2 d-flex justify-content-center">
        <div>
          <b-button
            variant="outline-primary"
            @click="closeSelectStoresPopup"
          >
            Cancel
          </b-button>
        </div>
        <div class="ml-1">
          <b-button
            variant="primary"
            @click="onClickPlaceOrder"
          >
            Place an order for selected stores
          </b-button>
        </div>
      </div>

    </div>
  </b-modal>
</template>

<script>
import { BButton, BCard, BModal } from 'bootstrap-vue'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'

import { formatNumber } from '@/@core/utils/utils'
import StoresList from './components/StoresList.vue'

const { formatCurrency } = utils

const { CURRENCY_CONVERSIONS } = c

export default {
  name: 'SelectStoresPlaceOrderModal',
  components: {
    StoresList,
    BModal,
    BButton,
    BCard,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    cartValuesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      totalOrderValue: {
        totalCartsValue: 0,
        totalCartsUnits: 0,
        totalCartsRRP: 0,
        currency: CURRENCY_CONVERSIONS.USD.SYMBOL,
      },
      formatCurrency,
      formatNumber,
      selectedStoreIds: [],
    }
  },
  emits: ['on-click-place-order'],
  methods: {
    closeSelectStoresPopup() {
      this.$bvModal.hide(this.modalId)
    },
    onSelectedStores(list) {
      this.totalOrderValue.currency = list[0]?.currency || CURRENCY_CONVERSIONS.USD.SYMBOL
      this.totalOrderValue.totalCartsValue = list.reduce((acc, val) => acc + Number(val.totalValues), 0)
      this.totalOrderValue.totalCartsUnits = list.reduce((acc, val) => acc + Number(val.totalUnits), 0)
      this.totalOrderValue.totalCartsRRP = list.reduce((acc, val) => acc + Number(val.totalRRP), 0)
      this.selectedStoreIds = list.map(store => store.storeId)
    },
    onClickPlaceOrder() {
      this.$emit('on-click-place-order', this.selectedStoreIds)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped>
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";

.checkout-options {
    .price-details {
      .price-title {
        font-weight: $font-weight-bolder;
        margin-bottom: 0.75rem;
        color: $dark-grey;
        font-size: 1.25rem;
      }

      .price-detail {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.75rem;
        color: $actions-text-grey;

        .detail-title {
          &.detail-total {
            font-weight: $font-weight-bolder;
          }
        }
        .total-price {
          color: $dark-grey;
          font-weight: $font-weight-bolder;
        }
      }
    }

  }

.cart-order-details-card {
  background-color: $gray-200;

  .card-body {
    padding: 1rem;
  }
}

</style>
