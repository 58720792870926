<template>
  <div class="row">
    <div class="col-12 col-lg-6">
      <b-button
        variant="outline-secondary-black"
        block
        :disabled="!isTermsAccepted || isPlacingOrder"
        @click="onClickSelectStoresAndPlaceOrder"
      >
        <span v-if="!isPlacingOrder">
          Select stores & place Order
        </span>
        <b-spinner
          v-else
          small
        />
      </b-button>
    </div>
    <div class="col-12 col-lg-6 mt-1 mt-lg-0">
      <b-button
        variant="secondary-black"
        block
        :disabled="!isTermsAccepted || isPlacingOrder"
        @click="onClickPlaceOrder"
      >
        <span v-if="!isPlacingOrder">
          Place Order for all stores
        </span>
        <b-spinner
          v-else
          small
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
    name: 'PlaceOrderButton',
    components: {
        BButton,
        BSpinner,
    },
    props: {
        isTermsAccepted: {
            type: Boolean,
            default: false,
        },
        isPlacingOrder: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-click-place-order', 'on-click-select-stores-and-place-order'],
    methods: {
        onClickPlaceOrder() {
            this.$emit('on-click-place-order')
        },
        onClickSelectStoresAndPlaceOrder() {
          this.$emit('on-click-select-stores-and-place-order')
        },
    },
}
</script>
