// Calculating Pieces, Total MRP, RRP Total
  export const calculateProductData = product => {
    const data = {
      Department: null,
      Category: null,
      SubCategory: null,
      Gender: null,
      Pieces: 0,
      RRPTotal: 0,
      TotalCost: 0,
      Budget: 0,
      Variance: '0%',
      currency: '',
    }
    data.Pieces = product.lineTotalQuantity
    data.TotalCost = product.lineTotalPriceWithDiscount || product.lineTotalPrice
    data.Department = product.department
    data.Category = product.category
    data.SubCategory = product.subCategory
    data.Gender = product.gender
    data.RRPTotal = (product.quantity * product.retailPrice * data.Pieces).toFixed(2)
    data.currency = product.currency
    return data
  }

  export const filterOptionCount = filteringProduct => {
    const filterResult = {
        Department: [],
        Category: [],
        SubCategory: [],
        Gender: [],
    }
    filteringProduct.map(item => {
        const groups = Object.keys(filterResult)
        groups.forEach(group => {
            const keys = Object.keys(filterResult[group])
            if (keys.indexOf(item[group]) === -1) filterResult[group][`${item[group]}`] = item.OptionCount
            else filterResult[group][`${item[group]}`] += item.OptionCount
        })
        return item
    })
    return filterResult
}

export const getCategorisedProduct = products => {
  // categorising each products
  const categorisedProduct = {}
  let differentKey = 0
  products.forEach(product => {
    const productKey = `${product.department}--${product.category}--${product.subCategory}--${product.gender}`
    const productKeys = Object.keys(categorisedProduct)
    if (productKeys.indexOf(productKey) !== -1) {
      const productData = calculateProductData(product)
      categorisedProduct[productKey].OptionCount += 1
      categorisedProduct[productKey].RRPTotal = (parseFloat(categorisedProduct[productKey].RRPTotal) + parseFloat(productData.RRPTotal)).toFixed(2)
      categorisedProduct[productKey].TotalCost = (parseFloat(categorisedProduct[productKey].TotalCost) + parseFloat(productData.TotalCost)).toFixed(2)
      categorisedProduct[productKey].Pieces += parseInt(productData.Pieces)
    }
    else {
      differentKey++
      const productData = calculateProductData(product)
      productData.OptionCount = 1
      productData.uniqKey = differentKey
      categorisedProduct[productKey] = productData
    }
  })
  return categorisedProduct
  }
