<template>
  <div>
    <h5>Please complete the following sections on the profile:</h5>
    <div>
      <div class="mt-1">
        <feather-icon
          :class="getClass(validateProfileData('general'))"
          icon="CheckCircleIcon"
          size="18"
        />
        <span class="font-weight-bolder"> General:</span>
        <div class="ml-3">
          <div>
            <feather-icon
              :class="getClass(profileData.general.companyName)"
              icon="CheckCircleIcon"
              size="18"
            />
            Company Name
          </div>
          <div>
            <feather-icon
              :class="getClass(profileData.general.firstName)"
              icon="CheckCircleIcon"
              size="18"
            />
            Main Contact Name
          </div>
          <div>
            <feather-icon
              :class="getClass(profileData.general.email)"
              icon="CheckCircleIcon"
              size="18"
            />
            Email
          </div>
          <div>
            <feather-icon
              :class="getClass(profileData.general.phone)"
              icon="CheckCircleIcon"
              size="18"
            />
            Phone Number
          </div>
        </div>
      </div>
      <div class="mt-1">
        <feather-icon
          :class="getClass(validateProfileData('addresses'))"
          icon="CheckCircleIcon"
          size="18"
        />
        <span class="font-weight-bolder"> Addresses:</span>
        <div class="ml-3">
          <div>
            <feather-icon
              :class="getClass(profileData.billingAddress.addressLine1)"
              icon="CheckCircleIcon"
              size="18"
            />
            Billing Address
          </div>
          <div>
            <feather-icon
              :class="getClass(profileData.shippingAddress.addressLine1)"
              icon="CheckCircleIcon"
              size="18"
            />
            Shipping Address
          </div>
        </div>
      </div>
      <div class="mt-1">
        <feather-icon
          :class="getClass(validateProfileData('documents'))"
          icon="CheckCircleIcon"
          size="18"
        />
        <span class="font-weight-bolder"> Documents:</span>
        <div class="ml-3">
          <div>
            <feather-icon
              :class="
                getClass(
                  profileData.documents.tradeLicense &&
                    profileData.documents.tradeLicenseNumber
                )
              "
              icon="CheckCircleIcon"
              size="18"
            />
            Trade License
          </div>
          <div>
            <feather-icon
              :class="getClass(profileData.documents.vatCertificate)"
              icon="CheckCircleIcon"
              size="18"
            />
            VAT
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OrderValidationMessage',
  props: {
    profileData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    validateProfileData(section) {
      switch (section) {
        case 'general': {
          if (
            this.profileData.general.companyName
            && this.profileData.general.firstName
            && this.profileData.general.email
            && this.profileData.general.phone
          ) {
            return true
          }
          return false
        }
        case 'addresses': {
          if (
            this.profileData.billingAddress.addressLine1
            && this.profileData.shippingAddress.addressLine1
          ) {
            return true
          }
          return false
        }
        case 'documents': {
          if (
            this.profileData.documents.tradeLicense
            && this.profileData.documents.tradeLicenseNumber
            && this.profileData.documents.vatCertificate
          ) {
            return true
          }
          return false
        }
        default: {
          return false
        }
      }
    },
    getClass(data) {
      if (data) {
        return 'text-light bg-info rounded-pill'
      }
      return 'text-secondary'
    },
  },
}
</script>
