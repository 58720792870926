<template>
  <div>
    <div
      class="w-100"
    >
      <div class="w-100">
        <cart-actions
          :cart-list="cartList"
          :cart-values-list="cartValuesList"
          :total-order-value="totalOrderValue"
        />
      </div>
      <!-- carts total -->
      <div class="w-100 mt-1">
        <carts-total
          :cart-list="cartList"
          :cart-values-list="cartValuesList"
          :total-order-value="totalOrderValue"
          :on-place-order-success="loadCarts"
        />
      </div>
      <!-- cart list -->
      <b-card class="w-100 mt-1 cart-list-card border-0">
        <cart-list
          :cart-list="cartList"
          :load-carts="loadCarts"
          @get-cart-values-list="getCartValuesList"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { mapState } from 'vuex'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import CartList from './CartList.vue'
import CartsTotal from './CartsTotal.vue'
import CartActions from './CartActions.vue'

const { CURRENCY_CONVERSIONS } = c

export default {
  name: 'Carts',
  components: {
    CartsTotal,
    CartList,
    BCard,
    CartActions,
  },
  props: {
    loadCarts: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      cartValuesList: [],
      totalOrderValue: {
        totalCartsValue: 0,
        totalCartsUnits: 0,
        totalCartsRRP: 0,
        currency: CURRENCY_CONVERSIONS.USD.SYMBOL,
      },
    }
  },
  computed: {
    ...mapState({
      cartList: state => state.checkout.carts,
    }),
  },
  mounted() {
    analytics.track(constants.TRACKS.PAGES.RETAILER_OPENS_CART_WITH_MULTIPLE_STORES, { cartList: this.cartValuesList })
  },
  methods: {
    calculateTotalValues(list) {
      this.totalOrderValue.currency = list[0]?.currency || CURRENCY_CONVERSIONS.USD.SYMBOL
      this.totalOrderValue.totalCartsValue = list.reduce((acc, val) => acc + Number(val.totalValues), 0)
      this.totalOrderValue.totalCartsUnits = list.reduce((acc, val) => acc + Number(val.totalUnits), 0)
      this.totalOrderValue.totalCartsRRP = list.reduce((acc, val) => acc + Number(val.totalRRP), 0)
    },
    getCartValuesList(cartValuesList) {
      this.cartValuesList = cartValuesList
      this.calculateTotalValues(cartValuesList)
    },
  },
}
</script>

<style lang="scss" scoped>
.cart-list-card {
  .card-body {
    padding: 0;
  }
}
</style>
