<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.70717 1.65822C6.2344 0.968757 7.9396 0.776124 9.5822 1.1075C11.2248 1.43888 12.7219 2.27754 13.8622 3.50517C15.0026 4.7328 15.7286 6.28742 15.9379 7.94971C15.9993 8.4367 15.6541 8.88119 15.167 8.9425C14.68 9.00382 14.2354 8.65874 14.1741 8.17174C14.0113 6.87881 13.4465 5.66962 12.5596 4.71477C11.6726 3.75992 10.5082 3.10761 9.23057 2.84986C7.95296 2.59211 6.62665 2.74194 5.43876 3.27821C4.41132 3.74205 3.53229 4.47474 2.892 5.39452H5.33333C5.82425 5.39452 6.22222 5.79242 6.22222 6.28325C6.22222 6.77409 5.82425 7.17199 5.33333 7.17199H0.888889C0.397969 7.17199 0 6.77409 0 6.28325V1.83957C0 1.34874 0.397969 0.950838 0.888889 0.950838C1.37981 0.950838 1.77778 1.34874 1.77778 1.83957V3.91974C2.56043 2.95182 3.56211 2.17516 4.70717 1.65822ZM0.832963 8.95642C1.32004 8.89511 1.7646 9.24019 1.82593 9.72718C1.98874 11.0201 2.55346 12.2293 3.44042 13.1842C4.32738 14.139 5.49181 14.7913 6.76943 15.0491C8.04704 15.3068 9.37335 15.157 10.5612 14.6207C11.5887 14.1569 12.4677 13.4242 13.108 12.5044H10.6667C10.1757 12.5044 9.77778 12.1065 9.77778 11.6157C9.77778 11.1248 10.1757 10.7269 10.6667 10.7269H15.1111C15.602 10.7269 16 11.1248 16 11.6157V16.0594C16 16.5502 15.602 16.9481 15.1111 16.9481C14.6202 16.9481 14.2222 16.5502 14.2222 16.0594V13.9792C13.4396 14.9471 12.4379 15.7238 11.2928 16.2407C9.7656 16.9302 8.0604 17.1228 6.4178 16.7914C4.77521 16.46 3.27813 15.6214 2.13779 14.3938C0.997446 13.1661 0.271396 11.6115 0.0620734 9.94922C0.00074927 9.46223 0.345888 9.01774 0.832963 8.95642Z"
      fill="#16ACD9"
    />
  </svg>
</template>
<script>
export default {
  name: 'RecycleIcon',
}
</script>
