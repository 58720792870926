<template>
  <div class="mt-2">
    <b-button
      variant="outline-primary "
      class="border-2 filter-button"
      @click="showFilter = !showFilter"
    >
      <b-img
        :src="filterIcon"
        alt="Error Icon"
      />
      Filter
    </b-button>
    <div
      v-if="showFilter"
      class="filter-div p-2 table-responsive"
    >
      <b-card-text class="filter-heading">
        Department
      </b-card-text>
      <b-form-checkbox-group
        id="Department"
        v-model="filteredProducts.Department"
        name="Department"
        class="mb-2"
        stacked
        @input="updateFilter('Department')"
      >
        <b-form-checkbox
          v-for="group in Object.keys(productGroups.Department)"
          :key="group"
          :value="group"
          class="filter-checkbox"
        >
          <div class="checkbox-div">
            <span>{{ group }}</span>
            <span class="group-count"> {{ productGroups.Department[`${group}`] }} </span>
          </div>
        </b-form-checkbox>
      </b-form-checkbox-group>
      <b-card-text class="filter-heading">
        Category
      </b-card-text>
      <b-form-checkbox-group
        id="Category"
        v-model="filteredProducts.Category"
        name="Category"
        class="mb-2"
        stacked
        @input="updateFilter('Category')"
      >
        <b-form-checkbox
          v-for="group in Object.keys(productGroups.Category)"
          :key="group"
          :value="group"
          class="filter-checkbox"
        >
          <div class="checkbox-div">
            <span>{{ group }}</span>
            <span class="group-count"> {{ productGroups.Category[`${group}`] }} </span>
          </div>
        </b-form-checkbox>
      </b-form-checkbox-group>
      <b-card-text class="filter-heading">
        SubCategory
      </b-card-text>
      <b-form-checkbox-group
        id="SubCategory"
        v-model="filteredProducts.SubCategory"
        name="SubCategory"
        class="mb-2"
        stacked
        @input="updateFilter('SubCategory')"
      >
        <b-form-checkbox
          v-for="group in Object.keys(productGroups.SubCategory)"
          :key="group"
          :value="group"
          class="filter-checkbox"
        >
          <div class="checkbox-div">
            <span>{{ group }}</span>
            <span class="group-count"> {{ productGroups.SubCategory[`${group}`] }} </span>
          </div>
        </b-form-checkbox>
      </b-form-checkbox-group>
      <b-card-text class="filter-heading">
        Gender
      </b-card-text>
      <b-form-checkbox-group
        id="Gender"
        v-model="filteredProducts.Gender"
        name="Gender"
        class="mb-2"
        stacked
        @input="updateFilter('Gender')"
      >
        <b-form-checkbox
          v-for="group in Object.keys(productGroups.Gender)"
          :key="group"
          :value="group"
          class="filter-checkbox"
        >
          <div class="checkbox-div">
            <span>{{ group }}</span>
            <span class="group-count"> {{ productGroups.Gender[`${group}`] }} </span>
          </div>
        </b-form-checkbox>
      </b-form-checkbox-group>
    </div>
    <div
      :key="filterkey"
      class="mt-2 table-responsive summary-table"
    >
      <b-table
        :fields="headings"
        :items="filterProduct(activeFilterGroup)"
      >
        <template #cell(Pieces)="data">
          <span>  {{ data.item.Pieces ? formatNumber(data.item.Pieces) : 0 }}</span>
        </template>
        <template #cell(TotalCost)="data">
          <span v-if="data.item"> {{ formatCurrency(data.item.TotalCost , data.item.currency) }} </span>
        </template>
        <template #cell(RRPTotal)="data">
          <span v-if="data.item"> {{ formatCurrency(data.item.RRPTotal, data.item.currency) }}</span>
        </template>
        <template #cell(Budget)="data">
          <b-form-input
            v-if="data.item.OptionCount"
            v-model.number="data.item.Budget"
            type="number"
            class="summary-table-input-width"
            min="0"
            @input="updateVariance(data)"
          />
        </template>
        <template #cell(Variance%)="data">
          <b-badge
            v-if="data.item.Variance"
            pill
            :variant="data.item.Variance.split('%')[0] > 0 ? 'danger':'success' "
          >
            {{ data.item.Variance }}
          </b-badge>
        </template>
      </b-table>
    </div>
    <div class="my-2 d-flex justify-content-center">
      <b-button
        variant="outline-info"
        class="mr-2 px-sm-3"
        @click="$emit('closeModal')"
      >
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BTable, BFormInput, BBadge, BButton, BFormCheckboxGroup, BImg, BFormCheckbox, BCardText,
} from 'bootstrap-vue'
import { reactive } from '@vue/composition-api'
import { kpApi, kpEndpoint } from '@/axios'
import { utils, productUtil } from '@kingpin-global/kingpin-utils-frontend'
import { formatNumber } from '@/@core/utils/utils'

import { BUYING_SUMMARY_TABLE_FIELDS } from '@/table-columns-constants'
import { calculateProductData } from '../checkout-v2/buying-summary-utils'

const { getProductAndCartTotals } = productUtil

const { formatCurrency } = utils

export default {
  components: {
    BTable,
    BButton,
    BFormInput,
    BBadge,
    BImg,
    BFormCheckbox,
    BFormCheckboxGroup,
    BCardText,
  },
  props: {
    cartProducts: {
      type: Array,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      filterIcon: require('@/assets/images/icons/filterIcon.svg'),
      showFilter: false,
      filterkey: 1,
      activeFilterGroup: null,
      headings: BUYING_SUMMARY_TABLE_FIELDS(),
    }
  },
  methods: {
    updateFilter(group) {
      this.activeFilterGroup = group
      this.filterkey += 1
      this.filterProduct(group)
    },
  },
  setup(props) {
    const tableItems = reactive([])
    const products = props.cartProducts
    getProductAndCartTotals(products)
    const productGroups = {
      Department: [],
      Category: [],
      SubCategory: [],
      Gender: [],
    }
    let activeFilter = null
    const filteredProducts = {
      Department: [],
      Category: [],
      SubCategory: [],
      Gender: [],
    }

    // categorising each products
    const categorisedProduct = {}
    let differentKey = 0
    products.forEach(product => {
      const productKey = `${product.department}--${product.category}--${product.subCategory}--${product.gender}`
      const productKeys = Object.keys(categorisedProduct)
      if (productKeys.indexOf(productKey) !== -1) {
        const productData = calculateProductData(product, productKey)
        categorisedProduct[`${productKey}`].OptionCount += 1
        categorisedProduct[`${productKey}`].RRPTotal = (parseFloat(categorisedProduct[`${productKey}`].RRPTotal) + parseFloat(productData.RRPTotal)).toFixed(2)
        categorisedProduct[`${productKey}`].TotalCost = (parseFloat(categorisedProduct[`${productKey}`].TotalCost) + parseFloat(productData.TotalCost)).toFixed(2)
        categorisedProduct[`${productKey}`].Pieces += parseInt(productData.Pieces)
      }
      else {
        differentKey++
        const productData = calculateProductData(product)
        productData.OptionCount = 1
        productData.uniqKey = differentKey
        categorisedProduct[`${productKey}`] = productData
      }
    })
    // Assign products value to TableItems
    const keys = Object.keys(categorisedProduct)
    if (Array.isArray(keys)) {
      keys.forEach(key => {
        tableItems.push(categorisedProduct[`${key}`])
      })
    }
    // filter Option with count

    const filterOptionCount = filteringProduct => {
      const filterResult = {
        Department: [],
        Category: [],
        SubCategory: [],
        Gender: [],
      }
      filteringProduct.map(item => {
        const groups = Object.keys(filterResult)
        groups.forEach(group => {
          const keys = Object.keys(filterResult[`${group}`])
          if (keys.indexOf(item[`${group}`]) === -1) filterResult[`${group}`][`${item[`${group}`]}`] = item.OptionCount
          else filterResult[`${group}`][`${item[`${group}`]}`] += item.OptionCount
        })
        return item
      })
      return filterResult
    }

    // Update Variance on Input
    const updateVariance = data => {
      if (data.item.Budget > 0) data.item.Variance = `${(((data.item.TotalCost / data.item.Budget) - 1) * 100).toFixed(2)}%`
      else data.item.Variance = '0%'
    }
    // Filtering Product with user Input
    const filterProduct = group => {
      activeFilter = group
      const filteringResult = {
        tableItems,
        Department: null,
        SubCategory: null,
        Category: null,
        Gender: null,
      }
      const keys = Object.keys(filteredProducts)
      const items = ['tableItems', 'Department', 'Category', 'SubCategory']
      keys.forEach((key, index) => {
        filteringResult[`${key}`] = filteringResult[`${items[index]}`].filter(item => filteredProducts[`${key}`].every(() => filteredProducts[`${key}`].includes(String(item[`${key}`]))))
        if (filteringResult[`${key}`].length === 0) filteringResult[`${key}`] = filteringResult[`${items[index]}`]
      })
      if (filteringResult.Gender.length === 0) filteringResult.Gender = tableItems
      // Setting Updated Filters

      const resultFilter = filterOptionCount(filteringResult.Gender)
      keys.forEach(key => {
        if (!group) Vue.set(productGroups, key, resultFilter[`${key}`])
        else if (key !== group && filteredProducts[`${key}`].length === 0) Vue.set(productGroups, key, resultFilter[`${key}`])
      })
      return filteringResult.Gender
    }
    return {
      products,
      updateVariance,
      tableItems,
      formatNumber,
      formatCurrency,
      kpApi,
      kpEndpoint,
      calculateProductData,
      productGroups,
      filterOptionCount,
      filterProduct,
      activeFilter,
      filteredProducts,
    }
  },
}
</script>
