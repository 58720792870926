<template>
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.77777 0.449378L4.01233 3.90618L6.08641 3.90618L6.08641 10.1284L7.46913 10.1284L7.46913 3.90618L9.54321 3.90618L6.77777 0.449378Z"
      fill="currentColor"
    />
    <path
      d="M11.6172 5.46191H8.85174V6.84463H11.6172V13.0669H1.93814V6.84463H4.70358V5.46191H1.93814C1.17557 5.46191 0.55542 6.08206 0.55542 6.84463V13.0669C0.55542 13.8294 1.17557 14.4496 1.93814 14.4496H11.6172C12.3797 14.4496 12.9999 13.8294 12.9999 13.0669V6.84463C12.9999 6.08206 12.3797 5.46191 11.6172 5.46191Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'ExportIcon',
}
</script>
