<template>
  <div class="stores-list-container">
    <div class="row m-0 mb-1 border border-1 rounded p-25">
      <div class="col-6 p-0 m-0">
        <b-button
          block
          :variant="selectedStoreOption === STORE_OPTION.BY_STORE ? 'primary' : 'none'"
          @click="selectedStoreOption = STORE_OPTION.BY_STORE"
        >
          {{ STORE_OPTION.BY_STORE }}
        </b-button>
      </div>
      <div class="col-6 p-0 m-0">
        <!-- TODO: Disabled for now -->
        <b-button
          block
          :variant="selectedStoreOption === STORE_OPTION.BY_SHIPPING_ADDRESS ? 'primary' : 'none'"
          disabled
          @click="selectedStoreOption = STORE_OPTION.BY_SHIPPING_ADDRESS"
        >
          {{ STORE_OPTION.BY_SHIPPING_ADDRESS }}
        </b-button>
      </div>
    </div>
    <v-select
      v-model="selectedStores"
      :options="cartValuesList"
      label="storeName"
      placeholder="Select stores"
      multiple
      clearable
      :searchable="false"
      deselect-from-dropdown
    />

    <div class="border border-1 rounded mt-50 stores-list-block">
      <div
        v-for="cartValue in cartValuesList"
        :key="'store' + cartValue.storeId"
        class="d-flex cursor-pointer align-items-center m-1"
        @click="onSelectStore(cartValue)"
      >

        <b-form-checkbox
          :checked="isSelected(cartValue)"
          :checked-value="true"
          :unchecked-value="false"
          @change="onSelectStore(cartValue)"
        />
        <div>
          {{ cartValue.storeName }}
        </div>
        <div
          v-if="!showOnlyList"
          class="d-flex ml-auto"
        >
          <b-badge class="mr-50 p-50 values-badge color-neutral-black">
            Total units: {{ formatNumber(cartValue.totalUnits) || 0 }}
          </b-badge>
          <b-badge class="mr-50 p-50 values-badge color-neutral-black">
            Total cost: {{ formatCurrency(Number(cartValue.totalValues), cartValue.currency) }}
          </b-badge>
          <b-badge class="p-50 values-badge color-neutral-black">
            Total RRP: {{ formatCurrency(Number(cartValue.totalRRP), cartValue.currency) }}
          </b-badge>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNumber } from '@/@core/utils/utils'
import { utils } from '@kingpin-global/kingpin-utils-frontend'
import { BBadge, BButton, BFormCheckbox } from 'bootstrap-vue'
import vSelect from 'vue-select'

const { formatCurrency } = utils

const STORE_OPTION = {
  BY_STORE: 'Select by store',
  BY_SHIPPING_ADDRESS: 'Select by shipping address',
}

export default {
  name: 'StoresList',
  components: {
    vSelect,
    BFormCheckbox,
    BBadge,
    BButton,
  },
  props: {
    cartValuesList: {
      type: Array,
      required: true,
    },
    showOnlyList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedStores: [],
      formatCurrency,
      formatNumber,
      STORE_OPTION,
      selectedStoreOption: STORE_OPTION.BY_STORE,
    }
  },
  watch: {
    selectedStores(val) {
      this.$emit('update-selected-stores', val)
    },
  },
  created() {
    this.selectedStores = this.cartValuesList
  },
  methods: {
  isSelected(cartValue) {
    const isChecked = this.selectedStores.find(store => store.storeId === cartValue.storeId)
    return !!isChecked
  },
    onSelectStore(cartValue) {
      const isStorePresent = this.selectedStores.find(store => store.storeId === cartValue.storeId)
      if (isStorePresent) {
        this.selectedStores = this.selectedStores.filter(store => store.storeId !== cartValue.storeId)
      } else {
        this.selectedStores.push(cartValue)
      }
    },
  },
}
</script>

<style lang="scss">
.stores-list-container {
  .vs__dropdown-menu {
    display: none !important;
  }
}
</style>

<style lang="scss" scoped>
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";

.values-badge {
  background-color: $gray-200;
  margin: 4px;
}

.stores-list-block {
  max-height: 400px;
  overflow: auto;
}
</style>
