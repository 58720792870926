<template>
  <div class="p-1">
    <!-- Search section -->
    <div class="w-100">
      <cart-search-save-clear-section
        :cart-list="cartList"
        :is-saving-cart="isSavingCart || isClearingCart"
        @on-search-cart-products="onSearchCartProducts"
        @on-click-save-cart="onClickSaveCart"
        @on-click-clear-cart="$bvModal.show('clear_carts')"
        @on-selected-store="onSelectedStore"
      />
    </div>
    <!-- Cart list loop -->
    <div
      :key="reloadKey"
      class="w-100"
    >
      <div
        v-for="(cart, index) in filteredCartList"
        :key="'cart' + index"
      >
        <template v-if="!!cart.products.length">
          <hr>
          <cart-v2
            :cart="cart"
            @on-updated-cart-values="onUpdatedCartValues($event, index)"
          />
        </template>
      </div>
    </div>
    <clear-carts-modal
      @on-click-clear-cart="onClickClearCart"
    />
  </div>
</template>

<script>
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import { DELETE_CARTS, UPDATE_CARTS } from '@/store/modules/checkout-v2.module'
import { GET_COMMON_BASE } from '@/store/modules/common.module'
import analytics from '@/@core/utils/analytics'
import constants, { CART_PRODUCTS_SEARCH_FIELDS, CART_PRODUCTS_SEARCH_ITEMS_FIELDS } from '@/constants'
import { searchProductUsingFields } from '@/@core/utils/utils'
import _ from 'lodash'
import CartSearchSaveClearSection from './CartSearchSaveClearSection.vue'
import CartV2 from './CartV2.vue'
import ClearCartsModal from './components/ClearCartsModal.vue'

const CART_WITH_MULTIPLE_STORES = constants.TRACKS.ACTIONS.CART_WITH_MULTIPLE_STORES
const searchFields = CART_PRODUCTS_SEARCH_FIELDS
const itemFields = CART_PRODUCTS_SEARCH_ITEMS_FIELDS

export default {
  name: 'CartList',
  components: {
    CartV2,
    CartSearchSaveClearSection,
    ClearCartsModal,
  },
  props: {
    cartList: {
      type: Array,
      required: true,
    },
    loadCarts: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      cartValuesList: [],
      filteredCartList: [],
      isSavingCart: false,
      isClearingCart: false,
      reloadKey: 0,
    }
  },
  emits: ['get-cart-values-list'],
  created() {
    this.$store.dispatch(GET_COMMON_BASE)
    this.filteredCartList = this.cartList
  },
  methods: {
    onSelectedStore(storeId) {
      if (storeId) {
        this.filteredCartList = this.cartList.filter(cart => cart._id === storeId)
      } else {
        this.filteredCartList = this.cartList
      }
    },
    onSearchCartProducts: _.debounce(function onSearchCartProducts(val) {
      this.onSearchCartProductsByText(val)
    }, 500),
    onSearchCartProductsByText(searchText = '') {
      this.filteredCartList = this.filteredCartList.map(cart => {
        const originalCart = this.cartList.find(_cart => cart._id === _cart._id)
        const products = originalCart.products.filter(product => searchProductUsingFields(
            product,
            searchFields,
            searchText,
            itemFields,
          ))

        // Assign item values which are changed by users
        products.forEach(_product => {
          for (const [key, value] of Object.entries(_product)) {
            if (key !== 'items') {
              _product[key] = value
            }
          }
        })

        return {
          ...cart,
          products,
        }
      })
      this.reloadKey++
      analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_SEARCH_PRODUCTS_IN_CART, { searchText })
    },
    async onClickSaveCart() {
      analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_CLICKED_SAVE_CART_BUTTON, { carts: this.cartValuesList })
      this.isSavingCart = true
      await this.$store.dispatch(UPDATE_CARTS).then(res => {
        apiToastSuccess(res.data.message)
        this.isSavingCart = false
        analytics.track(CART_WITH_MULTIPLE_STORES.CART_SAVED_SUCCESSFULLY, { carts: this.cartValuesList })
      })
      .catch(err => {
        apiToastError(err)
        this.isSavingCart = false
        analytics.track(CART_WITH_MULTIPLE_STORES.GETTING_ERROR_WHILE_SAVING_CART, err?.response)
      })
    },
    onClickClearCart() {
      this.$bvModal.hide('clear_carts')
      this.isClearingCart = true
      const storeIds = this.filteredCartList.map(cart => cart.userId)
      this.$store.dispatch(DELETE_CARTS, storeIds).then(res => {
        apiToastSuccess(res.data.message)
        this.isClearingCart = false
        this.loadCarts()
        analytics.track(CART_WITH_MULTIPLE_STORES.RETAILER_CLICKED_CLEAR_CART_BUTTON, { carts: this.cartValuesList })
      })
      .catch(err => {
        apiToastError(err)
        this.isClearingCart = false
      })
    },
    onUpdatedCartValues(cartValues, index) {
      this.cartValuesList[index] = cartValues
      this.$emit('get-cart-values-list', this.cartValuesList)
    },
  },
}
</script>
