<template>
  <div>
    <div class="mb-0 row cart-toggle">
      <div
        class="col-2 cursor-pointer d-flex align-items-center collection-name color-neutral-black"
        @click="onClickCartToggle"
      >
        {{ cart.storeName }}
      </div>
      <div class="col-10 d-flex justify-content-end align-items-center">
        <b-badge class="mr-50 p-50 values-badge color-neutral-black">
          Total units: {{ formatNumber(cartValues.totalUnits) || 0 }}
        </b-badge>
        <b-badge class="mr-50 p-50 values-badge color-neutral-black">
          Total cost: {{ formatCurrency(Number(cartValues.totalValues), cartValues.currency) }}
        </b-badge>
        <b-badge class="mr-2 p-50 values-badge color-neutral-black">
          Total RRP: {{ formatCurrency(Number(cartValues.totalRRP), cartValues.currency) }}
        </b-badge>
        <feather-icon
          :icon="cartVisible ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="24"
          @click="onClickCartToggle"
        />
      </div>
    </div>
    <b-collapse
      id="order_details_collapse"
      v-model="cartVisible"
      class="mt-1"
    >
      <!-- Address section -->
      <div>
        <address-section :cart="cart" />
      </div>
      <!-- Cart products -->
      <div class="mt-1">
        <cart-products
          :cart-id="cart._id"
          :cart-entity-id="cart.entityId"
          :store-name="cart.storeName"
          :cart-products="cart.products"
          :collection-end-date="cart.collectionEndDate"
          @update-total="updateCartTotal"
          @update-total-units="updateCartTotalUnits"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BBadge, BCollapse } from 'bootstrap-vue'
import { formatNumber } from '@/@core/utils/utils'
import { utils, constants as c } from '@kingpin-global/kingpin-utils-frontend'

import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { mapState } from 'vuex'
import { POP_EXPANDED_CARTS, PUSH_EXPANDED_CARTS } from '@/store/modules/checkout-v2.module'
import CartProducts from './CartProducts.vue'
import AddressSection from './AddressSection.vue'

const { formatCurrency } = utils

const { CURRENCY_CONVERSIONS } = c

const CART_WITH_MULTIPLE_STORES = constants.TRACKS.ACTIONS.CART_WITH_MULTIPLE_STORES

export default {
  name: 'CartV2',
  components: {
    BBadge, BCollapse, CartProducts, AddressSection,
  },
  props: {
    cart: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cartVisible: false,
      formatNumber,
      formatCurrency,
      cartValues: {
        storeId: this.cart._id,
        storeName: this.cart.storeName,
        totalValues: 0,
        totalUnits: 0,
        totalRRP: 0,
        currency: null,
      },
    }
  },
  computed: {
    ...mapState({
        isCartHasErrors: state => state.checkout.isCartHasErrors,
        expandedCarts: state => state.checkout.expandedCarts,
    }),
  },
  watch: {
    expandedCarts: {
      handler(val) {
        if (!this.cartVisible) {
          this.cartVisible = val.includes(this.cart._id)
        }
      },
      immediate: true,
    },
  },
  created() {
    this.cartValues.currency = this.cart?.products[0]?.currency || CURRENCY_CONVERSIONS.USD.SYMBOL
  },
  emits: ['on-updated-cart-values'],
  methods: {
    onClickCartToggle() {
      this.cartVisible = !this.cartVisible
      if (this.cartVisible) {
        this.$store.commit(PUSH_EXPANDED_CARTS, this.cart._id)
      } else {
        this.$store.commit(POP_EXPANDED_CARTS, this.cart._id)
      }
      analytics.track(this.cartVisible ? CART_WITH_MULTIPLE_STORES.RETAILER_EXPANDS_CART : CART_WITH_MULTIPLE_STORES.RERAILER_COLLAPSES_CART, { cart: this.cart })
    },
    updateCartTotal({ totalMRP, totalRRP }) {
      this.cartValues.totalValues = Number(totalMRP).toFixed(2)
      this.cartValues.totalRRP = Number(totalRRP).toFixed(2)
    },
    updateCartTotalUnits(totalUnits) {
      this.cartValues.totalUnits = totalUnits
      this.$emit('on-updated-cart-values', this.cartValues)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";

.cart-toggle {
  .collection-name {
    font-size: 1.25rem;
    font-weight: 700;
  }

  .values-badge {
    background-color: $gray-200;
  }
}
</style>
